import * as types from '../constants/userConstant';
const initialState = {
    caseresponse: {}
}

function casereducer(state = initialState, action) {
    switch (action.type) {
        case types.CASE_SUCCESS:
            return {
                ...state,
                caseresponse: action.reesponse
            }
        default: return state
    }
}

export default casereducer
