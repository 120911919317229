import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
    const [open, setOpen] = React.useState(props.isdisplayAlert);
    const [openError, setErrorOpen] = React.useState(props.isdisplayAlertError);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(props.openConfirmDialog);
    const deleteMessage=props.deleteMessage

    useEffect(() => {
        console.log('in alert');
        //  setOpen(props.isdisplayAlert)
    }, [openError, props.isdisplayAlertErrorert])

    useEffect(() => {
        console.log('in alert');
        //  setOpen(props.isdisplayAlert)
    }, [open, props.isdisplayAlert])

    const handleClose = (event, reason) => 
       { 
        setOpen(false);
        props.updatedisplayAlert(false, 'close');
    };
    const handleErrorClose = (event, reason) => 
        {
        setErrorOpen(false);
        props.updatedisplayAlertError(false, 'close');
    };
    const handleDelete = () => {
        setOpen(false);
        props.updatedisplayAlert(false, 'delete');
    };

    //confirm dialog

   const handleCloseConfirmationDialog = (event,reason) => {
   
        let falg=(reason!='backdropClick');
   if(event!==undefined && event!==null && event.keyCode==27)
   {
    falg=false;
   }
       
        setOpenConfirmDialog(false);
        props.closeConfirmDialog(falg);
    };
    const handleCancelConfirmationDialog = (event,reason) => {
        setOpenConfirmDialog(false);
        props.closeConfirmDialog(reason!='backdropClick' && false);
    };
    const handleAgree = () => {
        console.log("I agree!");
        handleCloseConfirmationDialog();
        props.closeConfirmDialog(true);

    };
    const handleDisagree = () => {
        
        console.log("I do not agree.");
        handleCancelConfirmationDialog();
        props.closeConfirmDialog(false);
    };



    return (
        <>
            <div>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Delete Confirmation
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this timesheet entries?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDelete}>Yes</Button>
                        <Button onClick={handleClose} autoFocus>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            {openError && <div>

                <Dialog
                    open={openError}
                    onClose={handleErrorClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Error
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Error while saving data. Please try again after some time!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleErrorClose}>Ok</Button>

                    </DialogActions>
                </Dialog>
            </div>
            }

            {openConfirmDialog && <div>
                <Dialog
                    open={openConfirmDialog}
                    onClose={handleCloseConfirmationDialog}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {" Delete Confirmation"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {deleteMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAgree} color="primary" autoFocus>
                            Ok
                        </Button>
                        <Button onClick={handleDisagree} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            }
        </>
    );
}