/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import AlertDialog from "../../shared/AlertDialog";
import React, { useState, useEffect } from "react";
import MatDatagrid from "f1-mat-data-grid-react";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import "../pendingTasks/pendingTasks.css";
import "../pendingTasks/pendingTasks.scss";
import axios from "axios";
import { Paginator } from "../../shared/paginator";
import CircleIcon from "@mui/icons-material/Circle";
import {
    GET_ALLPENDING,CASE_SYNC,
    GET_PENDINGTASKS_BY_NEUTRAL_EMAIL,
    REALTIME_DATA,
    UPDATE_PENDINGTASKS,
    GET_PENDINGCOUNTFORNEUTRAL,
} from "../../api/baseURL";
import { get } from "../../api/api";
import Loading from "../../assets/img/loader.gif";
import * as Utility from "../../utils/utility";
import DatePicker from "react-date-picker";
import LoadingOverlay from 'react-loading-overlay';
import { decryptItem, encryptItem } from "../../shared/Encrypt";
const PendingTasks = (props) => {
    const history=props.history;
    ////const [isLoaded, setIsLoaded] = useState(true);
    const [displayProducts, setdisplayProducts] = useState([]);
    ////const [initalCasesData, setinitalCasesData] = useState([]);
    const [totalRecords, settotalRecords] = useState(0);
    const [pageNumber, setpageNumber] = useState(1);
    const [gridfilter, setgridfilter] = useState([]);
    const [recordPerPage, setrecordPerPage] = useState(20);
    const [filterByCase, setfilterByCase] = useState("All");
    const [filterByCaseDate, setfilterByCaseDate] = useState("");
    const [filterByCaseDateEQ, setfilterByCaseDateEQ] = useState("");
    const [orderDirection, setorderDirection] = useState("asc");
    const [orderBy, setorderBy] = useState("HearingDateISO");
    const [orderByColumn, setorderByColumn] = useState(2);

    const [datechange, setdatechange] = useState("");
    const [namIdchange, setnamIdchange] = useState(null);
    const [captionchange, setcaptionchange] = useState(null);
    const [statusChange, setstatusChange] = useState(null);
    const [hearingOfficerNamechange, sethearingOfficerName] = useState(null);
    const [isActive, setisActive] = useState(true);
    const [disableFilter, setdisableFilter] = useState(true);
    const [isdisplayAlertError, setdisplayAlertError] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [isConfirmedDelete, setIsConfirmedDelete] = useState(false);
    const [isRowData, setIsRowData] = useState(null);
    const [isLoading, SetIsloading] = useState(false);
    
    const isLoaded=true;
    const [pendingTaskFilter, setpendingTaskFilter] =
        useState(!!JSON.parse(localStorage.getItem("pendingTaskFilter")) ? JSON.parse(localStorage.getItem("pendingTaskFilter")) : []);
    const handleClick = (e, rowData, history) => {
        setisActive(true);
        e.preventDefault();
        encryptItem("hearingDetails", JSON.stringify({
            hearingCode: rowData.hearingCode, caseType: rowData.caseType,
            goBack: 'PendingTasks', pendingAt: rowData?.pendingAt ? rowData?.pendingAt : 'schedulingInformation'
        }));
        caseSync(rowData.caseCode, history);
    }
    const CaseNamehandleClick = (e, rowData, history) => {
        setisActive(true);
        e.preventDefault();
        encryptItem("hearingDetails", JSON.stringify({
            hearingCode: rowData.hearingCode, caseType: rowData.caseType,
            goBack: 'PendingTasks', pendingAt:'schedulingInformation'
        }));
        caseSync(rowData, history);
      }
    const closeConfirmDialog = (value) => {
        setOpenConfirmDialog(false);
        setIsConfirmedDelete(value);
        setDeleteMessage("");
    };
    
    useEffect(() => {
        if (isConfirmedDelete) {
            onstatusClick(isRowData);
            setIsConfirmedDelete(false);
        }
    }, [isConfirmedDelete]);

    const onstatusClick = ( rowData) => {
        axios
            .post(
                `${UPDATE_PENDINGTASKS}?pendingAt=${rowData.pendingAt}&caseCode=${rowData.caseCode}&hearingCode=${rowData.hearingCode}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            )
            .then((res_PendingTask) => {
                if (Utility.IsHearingOfficer()) {
                    get(
                        `${GET_PENDINGCOUNTFORNEUTRAL}?neutralEmail=${decryptItem("neutralsEmail")
                        }&userCode=${decryptItem("jCode"
                        )}`,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("token")}`,
                            },
                        }
                    ).then((resp2) => {
                        console.log("pendingTaskCount", resp2);
                        localStorage.setItem("pendingTaskCount", `${resp2.data}`);
                         window.location.reload();
                    });
                }
                else
                { window.location.reload();}
            })
            .catch((error) => {
                setdisplayAlertError(true);
                console.log(error);
            });
    }
          
          const caseSync=(caseCode,history)=>{
            const PMASyncbody = {
              caseCode: caseCode,
            };
          
            const config = {
              headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            };
            
           if(REALTIME_DATA){
            let flag = false;
            axios
              .post(CASE_SYNC, PMASyncbody, config)
              .then((res2) => {
                if (res2.status === 200) {
                    const url = `/caseDetails`;
                    history.push(url);
                    flag = true;
                      console.log("case data synced")
                    
          }
          }); 
          setTimeout(function(){
            // In the timer function that gets executed after 5 seconds, check // the flag value. If we got the response already it would have been // set to true. Otherwise false
            if (!flag)
            {
                const url = `/caseDetails`;
                history.push(url);
            }
            }, 15000); 
        }else{ const url = `/caseDetails`;
        history.push(url);
      }
          }
        
        const columns = Utility.IsHearingOfficer()
        ? [
            {
                title: "NAM ID",
                field: "caseReference",
                sorting: true,
                render: (rowData) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {getcasecolorcode(rowData.caseReference, rowData.caseType,rowData.isCommercialCase)}
                    </div>
                ),
            },
            {
                title: "Case Caption",
                field: "caseName",
                sorting: true,
                render: (rowData) => (
                    <div>
                      <Link
                      to={`/caseDetails`}
                        onClick={(e) => { CaseNamehandleClick(e, rowData, history) }}                        
                        //to={generatePath(`/caseDetails/:hearingCode/:caseType/cases/schedulingInformation`,rowData.hearingCode,rowData.caseType)}
                        style={{ color: "#145187", textDecoration: "underline" }}
                      >
                        <div className="tooltip1" style={{ textAlign: 'left' }}>{rowData.casetext}
                          <span className="tooltiptext" style={{ textAlign: 'left', paddingLeft: '2px', }}>{rowData.caseName}</span>
                        </div>
                      </Link>
                    </div>
                  ),
             
            },
            {
                title: "Date",
                field: "hearingDate",
                type: "date",
                render: (rowData) => Utility.getPendingdate(rowData.hearingDate),
                sorting: true,
            },
            {
                title: "Task",
                field: "pendingAt",
                sorting: true,
                render: (rowData) => (
                    
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {rowData?.pendingAt ? (
                            <div>
                                <Link
                                    ////to={`/caseDetails/${rowData.hearingCode}/${rowData.caseType}/PendingTasks/${rowData?.pendingAt}`}
                                    to={`/caseDetails`}
                                    onClick={(e)=>{handleClick(e,rowData,history);}}
                                >
                                    <a style={{ color: "#145187", textDecoration: "underline" }}>
                                        {rowData.pendingAt === "timesheets"
                                            ? "Timesheet submission"
                                            : rowData.pendingAt === "pma"
                                                ? rowData.caseType.includes("Mediation")
                                                    ? "PMA submission"
                                                    : "Decision submission"
                                                : rowData.pendingAt === "demographics"
                                                    ? "Demographics submission"
                                                    : rowData.pendingAt === "review" ?
                                                        "Timesheet review submission" : "No"}{" "}
                                        pending
                                    </a>
                                </Link>
                            </div>
                        ) : (
                            <div>
                                <Link
                                    ////to={`/caseDetails/${rowData.hearingCode}/${rowData.caseType}/PendingTasks/${rowData?.pendingAt}`}
                                    to={`/caseDetails`}
                                    onClick={(e)=>{handleClick(e,rowData,history);}}
                                >
                                    <a style={{ color: "#145187", textDecoration: "underline" }}>
                                        {rowData.pendingAt === "timesheets"
                                            ? "Timesheet"
                                            : rowData.pendingAt === "pma"
                                                    ? rowData.caseType.includes("Mediation")
                                                    ? "PMA"
                                                    : "Decision"
                                                : rowData.pendingAt === "demographics"
                                                    ? "Demographics"
                                                    : rowData.pendingAt === "review" ?
                                                    "Timesheet review" : "No"}{" "}
                                        submission pending
                                    </a>
                                </Link>
                            </div>)
                        }
                    </div>
                ),
            },
            
            {
                title: "Actions",
                field: "Actions",
                sorting: false,
                render: (rowData) => (
                      (Utility.IsCaseClosed(rowData.caseStatus)?
                    <div>
                         <button style={{ color: "#145187", textDecoration: "underline",border: "none" ,background: "none!important" }}
                            onClick={(e) => {setOpenConfirmDialog(true);
                                setDeleteMessage(
                                `Are you sure, You want to Mark as Complete?` );
                                setIsRowData(rowData);
                            //  onstatusClick(e, rowData, history); 
                         }} 
                        >
                                                     
                                Mark as Complete
                            
                        </button>
                    </div>
                    :"")
                )
                ,
               
            },
        ]
        : [
            {
                title: "NAM ID",
                field: "caseReference",
                sorting: true,
                render: (rowData) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {getcasecolorcode(rowData.caseReference, rowData.caseType, rowData.isCommercialCase)}
                    </div>
                ),
            },
            {
                title: "Case Caption",
                field: "caseName",
                sorting: true,
                render: (rowData) => (
                    <div>
                      <Link
                        onClick={(e) => { CaseNamehandleClick(e, rowData, history) }}
                        to={`/caseDetails`}
                        //to={generatePath(`/caseDetails/:hearingCode/:caseType/cases/schedulingInformation`,rowData.hearingCode,rowData.caseType)}
                        style={{ color: "#145187", textDecoration: "underline" }}
                      >
                        <div className="tooltip1" style={{ textAlign: 'left' }}>{rowData.casetext}
                          <span className="tooltiptext" style={{ textAlign: 'left', paddingLeft: '2px', }}>{rowData.caseName}</span>
                        </div>
                      </Link>
                    </div>
                  ),
            },
            {
                title: "Date",
                field: "hearingDate",
                render: (rowData) => Utility.getPendingdate(rowData.hearingDate),
                sorting: true,
            },

            { title: "Officer Name", field: "hearingOfficerName", sorting: true },
            {
                title: "Task",
                field: "pendingAt",
                sorting: true,
                render: (rowData) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {rowData?.pendingAt ? (
                            <div>
                                <Link
                                         ////to={`/caseDetails/${rowData.hearingCode}/${rowData.caseType}/PendingTasks/${rowData?.pendingAt}`}
                                         to={`/caseDetails`}
                                         onClick={(e)=>{handleClick(e,rowData,history);}}
                                   
                                >
                                    <a style={{ color: "#145187", textDecoration: "underline" }}>
                                        {rowData.pendingAt === "timesheets"
                                            ? "Timesheet"
                                            : rowData.pendingAt === "pma"
                                                ? rowData.caseType.includes("Mediation") 
                                                    ? "PMA"
                                                    : "Decision"
                                                : rowData.pendingAt === "demographics"
                                                    ? "Demographics"
                                                    : rowData.pendingAt === "review" ?
                                                    "Timesheet review" : "No"}{" "}
                
                                        submission pending
                                    </a>
                                </Link>
                            </div>
                        ) : (
                            <div>
                                <Link
                                    ////to={`/caseDetails/${rowData.hearingCode}/${rowData.caseType}/PendingTasks/${rowData?.pendingAt}`}
                                    to={`/caseDetails`}
                                    onClick={(e)=>{handleClick(e,rowData,history);}}
                                >
                                    <a style={{ color: "#145187", textDecoration: "underline" }}>
                                        {rowData.pendingAt === "timesheets"
                                            ? "Timesheet"
                                            : rowData.pendingAt === "pma"
                                                    ? rowData.caseType.includes("Mediation") 
                                                    ? "PMA"
                                                    : "Decision"
                                                : rowData.pendingAt === "demographics"
                                                    ? "Demographics"
                                                    : rowData.pendingAt === "review" ?
                                                    "Timesheet review" : "No"}{" "}
                                        submission pending
                                    </a>
                                </Link>
                            </div>)
                        }
                    </div>
                ),
            },
            {               
                title: "Actions",
                field: "Actions",
                sorting: false,
                render: (rowData) => (
                    <div>
                        <button style={{ color: "#145187", textDecoration: "underline",border: "none" ,background: "none!important" }}
                            onClick={(e) => {setOpenConfirmDialog(true);
                                setDeleteMessage(
                                `Are you sure, You want to Mark as Complete?` );
                                setIsRowData(rowData);
                            //  onstatusClick(e, rowData, history); 
                         }} 
                        >
                                                     
                                Mark as Complete
                            
                        </button>
                    </div>

                )
                ,
            },
        ];
    const colmn = Utility.IsHearingOfficer() ? "colmn4" : "col-md-3 col-6";
    useEffect(() => {
        getCases();
    }, [pageNumber, recordPerPage, gridfilter, orderDirection, orderBy]);
   
    const onChangePage = (e, page) => {
        setpageNumber(page + 1);
    };

    const handleSelectChange = (e) => {
        const size = e.target.value;
        setrecordPerPage(+size);
        setpageNumber(1);
    };
    const onFilterChange = (event) => { console.log("filter change") };

    const getObject = () => {
      
        return {
            PageNumber: pageNumber,
            PageSize: recordPerPage,
            Sort: orderBy,
            SortDirection: orderDirection,
            Filters: pendingTaskFilter.length > 0 ? pendingTaskFilter : gridfilter,
            NeutralEmail: decryptItem("neutralsEmail"),
            UserCode:decryptItem ("jCode"),
            UserType:decryptItem("roleId")
        };
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getFilterObjectfromSession = (filterArr) => {
        let name = "", filter = "";
        for (let i = 0; i < filterArr.length; i++) {
            filter = filterArr[i].filterValue;
            if (filterArr[i].filterName === "HearingDate") {
                name = "date";
                setdatechange(filterArr[i].filterValue);
            }
            if (filterArr[i].filterName === "CaseReference") {
                name = "namId";

                setnamIdchange(filterArr[i].filterValue);
            }
            if (filterArr[i].filterName === "CaseName") {
                name = "caption";

                setcaptionchange(filterArr[i].filterValue);
            }
            if (filterArr[i].filterName === "PendingAt") {
                name = "status";

                setstatusChange(filterArr[i].filterValue);
            }
            if (filterArr[i].filterName === "HearingOfficerName") {
                name = "officername";

                sethearingOfficerName(filterArr[i].filterValue);
            }
        }

        checkFilterApplicable(name, filter);

    }
    const getCases = () => {
        const element = document.getElementsByClassName("pendingTasksTable");
        setisActive(true);
        getFilterObjectfromSession(pendingTaskFilter);
        const url = Utility.IsHearingOfficer()
            ? GET_PENDINGTASKS_BY_NEUTRAL_EMAIL
            : GET_ALLPENDING;
        axios
            .post(url, getObject(), {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                element[0].classList.remove("no-records");
                if (res.status === 200) {
                    SetIsloading(false);
                    setisActive(false);
                    if (res.data !== null && res.data?.data.length > 0) {
                        //skiping records if commercial case has demographics(for old data)
                        //res.data.data= res.data?.data.filter(val => !(val.monthlyBillingType === true && val.pendingAt ==="demographics"));

                        for (let i = 0; i < res.data.data.length; i++) {
                            const caseName = res.data.data[i].caseName;
                            res.data.data[i].casetext = caseName.length > 0 ? caseName.substring(0, 100) : "";
                        }
                        setdisplayProducts(res.data.data);
                       //// setinitalCasesData(res.data.data);
                        settotalRecords(res.data.totalCount ? res.data.totalCount : 0);
                        if (pageNumber !== res.data.pageNumber) {
                            setpageNumber(res.data.pageNumber ? res.data.pageNumber : 0);
                        }
                    } else {
                        setdisplayProducts([]);
                       //// setinitalCasesData([]);
                        settotalRecords(0);
                        element[0].classList.add("no-records");

                    }
                }
            })
            .catch((error) => {
                setisActive(false);
                SetIsloading(false);
                return Promise.reject(error.response);
            });
    };

    const onFilterByCases = (e) => {
        let filterByCaseDateEQlocal = "";
        let filterByCaseDatelocal = "";
        const filterValue = e.target.value
        setfilterByCase(filterValue);
        if (filterValue === "Today") {
            filterByCaseDateEQlocal = "=";
            filterByCaseDatelocal = new Date(Date.now()).setHours(23, 59, 59, 99);
        } else if (filterValue === "Previous1week") {
            filterByCaseDateEQlocal = ">=";
            filterByCaseDatelocal = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).setHours(23, 59, 59, 99);
        } else if (filterValue === "Previous1month") {
            filterByCaseDateEQlocal = ">=";
            filterByCaseDatelocal = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).setHours(23, 59, 59, 99);
        } else if (filterValue === "Previous6months") {
            filterByCaseDateEQlocal = ">=";
            filterByCaseDatelocal = new Date(Date.now() - 180 * 24 * 60 * 60 * 1000).setHours(23, 59, 59, 99);
        } else if (filterValue === "Next1week") {
            filterByCaseDateEQlocal = "<=";
            filterByCaseDatelocal = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).setHours(23, 59, 59, 99);
        } else if (filterValue === "Next1month") {
            filterByCaseDateEQlocal = "<=";
            filterByCaseDatelocal = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).setHours(23, 59, 59, 99);
        } else if (filterValue === "Next6months") {
            filterByCaseDateEQlocal = "<=";
            filterByCaseDatelocal = new Date(Date.now() + 180 * 24 * 60 * 60 * 1000).setHours(23, 59, 59, 99);
        }
        checkFilterApplicable("filterbycase", filterValue);
        setfilterByCaseDateEQ(filterByCaseDateEQlocal);
        setfilterByCaseDate(filterValue === "Today"?new Date().toJSON().slice(0, 10):new Date(filterByCaseDatelocal));
        setdisableFilter(false);
    };
    const getcasecolorcode = (casecode, casetype, isCommercialCase) => {
        if(isCommercialCase){

            console.log("commercial case");

            let ssf=isCommercialCase;

        }
        return (
            <>
                {isCommercialCase && !Utility.IsHearingOfficer()?
                <div style={{ marginRight: "5px" }}><span><i className="ccircle">C</i></span></div>
                :
                <div
                    style={{ marginRight: "5px" }}
                    className={
                        casetype.includes("Mediation") 
                            ? "circleBase hold"
                            : "circleBase inprogress"
                    }
                ></div>}
                {casecode}
            </>
        );
    };

    const onChildMount = (dataFromChild) => {console.log("child mount") };
    const onOrderChange = (column, event) => {

        setorderByColumn(column);

        setorderDirection(event);

        const field = columns[column].field === "caseReference" ? "CaseReference" :
            (columns[column].field === "hearingDate" ? "HearingDateISO" :
                columns[column].field[0].toUpperCase() + columns[column].field.substring(1));
        setorderBy(field);
    };

    const handlehearingOfficerNameChange = (e) => {
        sethearingOfficerName(e.target.value);
        checkFilterApplicable("officername", e.target.value);
    };

    const handleNamIdChange = (e) => {
        let namidval = e.target.validity.valid ? e.target.value : namIdchange;
        if (namidval.length > 10) return;
        setnamIdchange(namidval);
        checkFilterApplicable("namId", namidval);
    };
    const handleCaptionChange = (e) => {
        setcaptionchange(e.target.value);
        checkFilterApplicable("caption", e.target.value);
    };

    const handlehearingOfficerNameBlur = (e) => {
        // TO DO document why this arrow function is empty    
    };

    const handleNamIdBlur = (e) => {
        // TO DO document why this arrow function is empty    
    };
    const handleCaptionBlur = (e) => {
        // TO DO document why this arrow function is empty    
    };

    const handleDateChange = (e) => {
        setdatechange(e);
        checkFilterApplicable("date", e); if (e === null) { setdisableFilter(false); }
    };
    const handleStatusChange = (e) => {
        setstatusChange(e.target.value);
        checkFilterApplicable("status", e.target.value);
    };
    const createFilterArr = (datechanged, statuschanged, filterByCaseDatelocal, filterByCaseDateEQlocal) => {
        const arr = [];
        if (datechange !== null && datechange !== "") {
            const tmObj = {
                filterName: "HearingDate",
                filterValue: datechange,
                filterType: "datetime",
                filterEq: (datechange.getTimezoneOffset() * -1).toString()

            };
            arr.push(tmObj);
        }
        if (filterByCaseDate !== null && filterByCaseDate !== "") {
            const tmObj = {
                filterName: "HearingDateISO",
                filterValue: filterByCaseDate,
                filterType: "compare",
                filterEq: filterByCaseDateEQ
            };
            arr.push(tmObj);
        }
        if (statusChange !== null && statusChange !== "") {
            const tmObj = {
                filterName: "PendingAt",
                filterValue: statusChange,
                filterType: "string",
                filterEq: ""
            };
            arr.push(tmObj);
        }
        if (namIdchange !== null && namIdchange !== "") {
            const tmObj = {
                filterName: "CaseReference",
                filterValue: namIdchange.trim(),
                filterType: "string", filterEq: ""
            };
            arr.push(tmObj);
        }
        if (captionchange !== null && captionchange !== "") {
            const tmObj = {
                filterName: "CaseName",
                filterValue: captionchange.trim(),
                filterType: "string", filterEq: ""
            };
            arr.push(tmObj);
        }
        if (!Utility.IsHearingOfficer()) {
            if (hearingOfficerNamechange !== null && hearingOfficerNamechange !== "") {
                const tmObj = {
                    filterName: "HearingOfficerName",
                    filterValue: hearingOfficerNamechange.trim(),
                    filterType: "string", filterEq: ""
                };
                arr.push(tmObj);
            }
        }
        const filteredArr = buldFilter(arr);
        setgridfilter(filteredArr);
    };
    const checkFilterApplicable = (name, value) => {

        setdisableFilter(!
            ((name === "date" ? (value !== undefined && value !== null && value !== '') : (datechange !== null && datechange !== ""))
                || (name === "filterbycase" ? (value !== undefined && value !== null && value !== '') : (filterByCaseDate !== null && filterByCaseDate !== ""))
                || (name === "status" ? (value !== undefined && value !== null && value !== '') : (statusChange !== null && statusChange !== ""))
                || (name === "namId" ? (value !== undefined && value !== null && value !== '') : (namIdchange !== null && namIdchange !== ""))
                || (name === "caption" ? (value !== undefined && value !== null && value !== '') : (captionchange !== null && captionchange !== ""))
                || (name === "officername" ? (value !== undefined && value !== null && value !== '') : (hearingOfficerNamechange !== null && hearingOfficerNamechange !== "")
                )));
    }
    const buldFilter = (arr) => {
        const tmpFilterArr = [];
        for (const element of arr) {
            const filterArr = {
                filterName: element.filterName,
                filterValue: typeof element.filterValue === "string" ? element.filterValue.trim() : element.filterValue,
                filterType: element.filterType,
                filterEq: element.filterEq//arr[i].column?.type ? arr[i].column?.type : "string"
            };
            tmpFilterArr.push(filterArr);
            setpendingTaskFilter(tmpFilterArr);
            localStorage.setItem("pendingTaskFilter", JSON.stringify(tmpFilterArr));

        }
        return tmpFilterArr;
    };
    const applyfilter = () => {
        createFilterArr("", "", "", "");
        setpageNumber(1);
        SetIsloading(true);
    }
    const clearfilter = () => {
        setpageNumber(1);
        setfilterByCase("All");
        setdisableFilter(false);
        setdatechange("");
        setnamIdchange("");
        setcaptionchange("");
        setstatusChange("");
        sethearingOfficerName("");
        setgridfilter([]);
        //  createFilterArr("", "")
        setpendingTaskFilter([]);
        localStorage.setItem("pendingTaskFilter", JSON.stringify([]));
        checkFilterApplicable("", "")
        setfilterByCaseDate("");
        setfilterByCaseDateEQ("");
        document.getElementById("clearFilterNamId").value=''
    }
    return (
        <div className="casesContainer pendingTasksTable" style={{ marginTop: "4.5rem" }}>
            {isLoaded ? (
                <div>
                    <div className="topBar">
                        <div className="leftContainer filterContainer">
                            <p
                                style={{
                                    marginRight: "15px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                }}
                            >
                                Filter cases by
                            </p>
                            <select value={filterByCase}
                                defaultValue={filterByCase}
                                className="SelectField"
                                name="sortBy"
                                id="filterSelect"
                                onChange={onFilterByCases}
                            >
                                <option value="All">All</option>
                                <option value="Previous6months">Previous 6 months</option>
                                <option value="Previous1month">Previous 1 month</option>
                                <option value="Previous1week">Previous 1 week</option>
                                <option value="Today">Today</option>
                                <option value="Next1week">Next 1 week</option>
                                <option value="Next1month">Next 1 month</option>
                                <option value="Next6months">Next 6 months</option>
                            </select>
                        </div>

                    </div>
                    {displayProducts && (
                        <div className="dataTable pendingTasks">
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <div className="row">
                                        <div className={colmn}>
                                            <input
                                                placeholder="NAM ID"
                                                onChange={handleNamIdChange}
                                                disabled = {isLoading}
                                                className="form-control"
                                                id="clearFilterNamId"
                                                value={namIdchange}
                                                onBlur={handleNamIdBlur}
                                                type="number"

                                                onKeyDown={(event) => {
                                                    if (event.key
                                                        ==
                                                        "-") {
                                                        event.preventDefault();

                                                    }
                                                    if (event.key === 'Enter') { applyfilter(); }
                                                }} 
                                                min={0}
                                                maxLength={10}
                                                pattern="[0-9]*"
                                            />
                                        </div>
                                        <div className={colmn}>
                                            <input
                                                type="text"
                                                placeholder="Case Caption"
                                                onChange={handleCaptionChange}
                                                className="form-control"
                                                value={captionchange}
                                                disabled = {isLoading}
                                                onBlur={handleCaptionBlur}
                                                maxLength="200"
                                                onKeyDown={(event)=>{
                                                    if(event.key === 'Enter'){applyfilter();}
                                              }}  />
                                        </div>
                                        <div className='col-md-3 col-6'>
                                            <DatePicker
                                            locale="en-US"
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                format="dd/MMM/yyyy"
                                                onChange={handleDateChange}
                                                value={datechange}
                                            />
                                        </div>
                                        {Utility.IsHearingOfficer() ? null : (
                                            <div className={colmn}>
                                                <input
                                                    placeholder="Hearing Officer Name"
                                                    type="text"
                                                    onChange={handlehearingOfficerNameChange}
                                                    disabled = {isLoading}
                                                    className="form-control"
                                                    value={hearingOfficerNamechange}
                                                    onBlur={handlehearingOfficerNameBlur}
                                                    onKeyDown={(event)=>{
                                                        if(event.key === 'Enter'){applyfilter();}
                                                  }}  />
                                            </div>
                                        )}
                                        {Utility.IsHearingOfficer() ?
                                            (
                                                <div className={colmn}>
                                                    <select
                                                        value={statusChange}
                                                        onChange={(e) => handleStatusChange(e)}
                                                        className="SelectField"
                                                        name="sortBy"
                                                        id="sortBy"
                                                    >
                                                        <option value="">Select Task</option>
                                                        <option value="demographics">
                                                            Demographics submission pending
                                                        </option>
                                                        {/* <option value="">No Submission pending</option> */}
                                                        <option value="pma">PMA/Decision submission pending</option>
                                                        <option value="timesheet">
                                                            Timesheet submission pending
                                                        </option>
                                                    </select>
                                                </div>
                                            ) : (
                                                <div className={colmn}>
                                                    <select
                                                        value={statusChange}
                                                        onChange={(e) => handleStatusChange(e)}
                                                        className="SelectField"
                                                        name="sortBy"
                                                        id="sortBy"
                                                    >
                                                        <option value="">Select Task</option>
                                                        <option value="demographics">
                                                            Demographics submission pending
                                                        </option>
                                                        {/* <option value="">No Submission pending</option> */}
                                                        <option value="pma">PMA/Decision submission pending</option>
                                                        <option value="timesheet">
                                                            Timesheet submission pending
                                                        </option>
                                                        <option value="review">
                                                            Timesheet review pending
                                                        </option>
                                                    </select>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                <div className="col-md-12 col-12 filtersbtn">
                                    <button onClick={applyfilter} className="btn btn-primary" disabled={disableFilter}>Apply Filter</button>
                                    <button onClick={clearfilter} className="btn btn-primary">
                                        Clear Filter
                                    </button>
                                </div>
                            </div>
                            <LoadingOverlay
                                active={isActive}
                                spinner
                                text='Loading your content...'>
                                <div className="eventTopPagination">
                                    <div className="caseTablePagination">
                                        <div className="controlDiv">
                                            <div className="eventTypes">
                                                {!Utility.IsHearingOfficer() &&
                                                    <div className="eventList">
                                                        <span><i className="ccircle">C</i></span>
                                                        <p>Commercial</p>
                                                    </div>}
                                                <div className="eventList">
                                                    <CircleIcon
                                                        style={{
                                                            color: "#F27C47",
                                                            fontSize: "0.6rem",
                                                            marginRight: "5px",
                                                        }}
                                                        size="small"
                                                    />
                                                    <p>Arbitration Event</p>
                                                </div>
                                                <div className="eventList">
                                                    <CircleIcon
                                                        style={{
                                                            color: "black",
                                                            fontSize: "0.6rem",
                                                            marginRight: "5px",
                                                        }}
                                                        size="small"
                                                    />
                                                    <p>Mediation Event</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="page">
                                        <div className="perPagecounter">
                                            <p style={{ padding: "6px", backgroundColor: "#e8e8e8" }}>
                                                Per Page
                                            </p>
                                            <select defaultValue={recordPerPage}
                                                onChange={(e) => handleSelectChange(e)}
                                                className="SelectField pageSize"
                                                name="sortBy"
                                                id="sortBy">
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="15">15</option>
                                                <option value="20">20</option>
                                            </select>
                                        </div>
                                        <div className="showingResContainer">
                                            <p>Showing {totalRecords==0?totalRecords: pageNumber > 1 ? ((pageNumber - 1) * recordPerPage) + 1 : pageNumber} - {((pageNumber * recordPerPage) <= totalRecords) ? pageNumber * recordPerPage : totalRecords} of {totalRecords} records</p>
                                        </div>
                                        <div className="paginDiv">
                                            {totalRecords > 0 ? (
                                                <Paginator
                                                    onMount={onChildMount}
                                                    rowCount={recordPerPage}
                                                    className="pagination"
                                                    totalRecoards={totalRecords}
                                                    recordsPerPage={recordPerPage}
                                                    onPageChange={onChangePage}
                                                    maxPageLimit={5}
                                                    initialPage={pageNumber}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <MatDatagrid
                                    rows={displayProducts ? displayProducts : []}
                                    columns={columns}
                                    grouping={false}
                                    pageSize={recordPerPage}
                                    title={" "}
                                    paging={false}
                                    showFirstLastPageButtons={false}
                                    paginationType={"stepped"}
                                    filtering={false}
                                    pageSizeOptions={[5, 10, 15, 20]}
                                    onFilterChange={onFilterChange}
                                    onChangePage={onChangePage}
                                    customPagination={{
                                        isDisplay: true,
                                        totalCount: totalRecords,
                                    }}
                                    isCustomHeader={true}
                                    selection={false}
                                    actionsColumnIndex={-1}
                                    onOrderChange={onOrderChange}
                                    orderDirection={orderDirection}
                                    orderBy={orderByColumn}
                                    draggable={false}
                                />

                            </LoadingOverlay>
                        </div>
                    )}
                </div>
            ) : (
                // eslint-disable-next-line jsx-a11y/iframe-has-title
                <div className="LoadingData">
                    <iframe
                        title=""
                        src={Loading}
                        width="100%"
                        allowtransparency="true"
                        frameBorder="0"
                        marginWidth="0"
                        marginHeight="0"
                        scrolling="no"
                    ></iframe>

                    <h4>Loading...</h4>
                </div>
            )}
              {openConfirmDialog && (
            <AlertDialog
                isdisplayAlert={false}
                isdisplayAlertError={false}
                openConfirmDialog={openConfirmDialog}
                closeConfirmDialog={closeConfirmDialog}
                deleteMessage={deleteMessage}
            ></AlertDialog>
        )}
        </div>
       
    );
};
export default PendingTasks;
