import Modal from "react-bootstrap/Modal";
import AlertDialog from "../../shared/AlertDialog";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import { demoGraphicFormConfig } from "./demoGraphicsFormConfig";
import DynamicForm from "f1-dynamic-form-react";
import LoadingOverlay from "react-loading-overlay";
import moment from 'moment';
import "./demoGraphics.scss";
import {
  GET_DEMOGRPAHICS,
  UPDATE_CMSDEMOGRPAHICS,
  isSyncBack,
  CREATE_DEMOGRPAHICS,
  UPDATE_PENDINGTASKS,
  GET_PENDINGCOUNTFORNEUTRAL,
  GRAMERLY_CLIENT_ID,
  GRAMERLY_ASSERTION_ID
} from "../../api/baseURL";
import { get } from "../../api/api";
import axios from "axios";
import * as Utility from "../../utils/utility";
import CaseData from "../cases/caseData";
import { decryptItem, encryptItem } from "../../shared/Encrypt";
document.querySelectorAll("grammarly-editor-plugin").forEach((grammarlyEditor) => {
  grammarlyEditor.config = {
    oauthAssertionProvider: async () => {
      // // Call your endpoint to get an assertion
      const response = await fetch(GRAMERLY_ASSERTION_ID, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          clientId: GRAMERLY_CLIENT_ID,
        }),
      });
      // // Handle errors
      if (!response.ok) {
        throw new Error("Error creating assertion");
      }
      // // Return generated assertion
      return await response.json();
    },
  };
});

export default function DemoGraphics(props) {
  const customLabel = "custom-label";
  const _0Liability = "0 / 0 Liability";
  const secondaryinformationdiv = "secondary-information-div";
  const tertiaryinformationdiv = "tertiary-information-div";
  const selectCounty = "--Select Venue County--";
  const [updateSateOfDemographics, setUpdateSateOfDemographics] =
    useState(null);
  const [isdisplayAlertError, setdisplayAlertError] = useState(false);
  const [demographicModel, setDemographicModel] = useState();
  const [afterMasterDataBind, setAfterMasterDataBind] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [issubmitted, setissubmitted] = useState(false);
  const [countyData, setCountyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTertiaryDeleted, setIsTertiaryDeleted] = useState(false)
  const [isSecondaryDeleted, setIsSecondaryDeleted] = useState(false)
  const [initalDatBind, setInitalDatBind] = useState(false)
  const [showAddPendingTasksButton, setShowAddPendingTasksButton] = useState(false)

  const checkAddToPendingTaskIfCaseIsCLosed=(e)=>{
    axios
      .post(
        `${UPDATE_PENDINGTASKS}?pendingAt=demographics&caseCode=${props.caseDetails["caseCode"]}&hearingCode=${props.caseDetails["hearingCode"]}&isPendingAddedForDemographics=${true}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res_PendingTask) => {
        
        console.log(res_PendingTask);
        setShowAddPendingTasksButton(!res_PendingTask.data)
      })
      .catch((error) => {
        setShowAddPendingTasksButton(false);

      });
  }
  const updatePendingTaskICaseIClosed = (e) => {
    e.preventDefault();
    axios
      .post(
        `${UPDATE_PENDINGTASKS}?pendingAt=demographics&caseCode=${props.caseDetails["caseCode"]}&hearingCode=${props.caseDetails["hearingCode"]}&addtopendingtasks=${true}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res_PendingTask) => {
        setShowAddPendingTasksButton(false);
        // if (Utility.IsHearingOfficer()) {
        //   get(
        //     `${GET_PENDINGCOUNTFORNEUTRAL}?neutralEmail=${decryptItem("neutralsEmail")}&userCode=${decryptItem("jCode"
        //     )}`,
        //     {
        //       headers: {
        //         Authorization: `Bearer ${localStorage.getItem("token")}`,
        //       },
        //     }
        //   ).then((resp2) => {
        //     console.log("pendingTaskCount", resp2);
        //     localStorage.setItem("pendingTaskCount", `${resp2.data}`);
        //   });
        // }
      })
      .catch((error) => {
        setdisplayAlertError(true);
        console.log(error);
      });
  }

  const isCasetypeArbitration =
    ((props.caseDetails?.caseMethod &&
      props.caseDetails.caseMethod.trim().toLowerCase() === "arbitration") || (props.caseDetails?.caseType &&
        props.caseDetails.caseType.trim().toLowerCase().includes("arbitration")))
      ? true
      : false;
  const today = new Date();
  let yesterday = new Date(today);
  yesterday = new Date(yesterday.setDate(today.getDate() - 1));
  const dataAfterUnSettled = [
    [
      {
        colmd: "4",
        key: "eco_label",
        type: "label",
        value: "Economic Loss",
        className: customLabel,
      },

      {
        "colmd": "1",
        "key": "amt_label_symbol",
        "type": "label",
        "value": "$",
        "className": "custom-label"
      }, {
        colmd: "4",
        key: "loss",
        label: "",
        type: "text",
        disabled: false
      }

    ],
    [
      {
        colmd: "4",
        key: "amt_label",
        type: "label",
        value: "Total amt Awarded",
        className: customLabel,
      },
      {
        "colmd": "1",
        "key": "amt_label_symbol",
        "type": "label",
        "value": "$",
        "className": "custom-label"
      },
      {
        colmd: "4",
        key: "awarded",
        label: "",
        type: "text",
        disabled: false
      }

    ],
    [
      {
        colmd: "4",
        key: "liab_splitlabel",
        type: "label",
        value: "Liability Split P/D",
        className: customLabel,
      },
      {
        colmd: "4",
        key: "split",
        label: "",
        type: "text",
      },
      {
        colmd: "4",
        key: "liab_split",
        type: "label",
        value: "/ 100 %",
        className: customLabel,
        hide: false,
      },
    ],
    [
      {
        colmd: "4",
        key: "liab_splitlabel_radio",
        type: "label",
        value: "",
        className: customLabel,
      },
      {
        colmd: "4",
        key: "splitRadio",
        className: "custom-label demogrpahic-split",
        label: "",
        disabled: false,
        type: "radio",
        options: [
          {
            key: _0Liability,
            label: _0Liability,
            name: "splitRadio",
            value: _0Liability,
            id: _0Liability,
          },
          {
            key: "unknown",
            label: "unknown",
            name: "splitRadio",
            value: "unknown",
            id: "unknown",
          },
        ],
      },
    ],
    [
      {
        colmd: "4",
        key: "liab_split_comments",
        type: "label",
        value: "Liability Split Comments",
        className: customLabel,
      },
      {
        colmd: "4",
        key: "splitNote",
        type: "textarea",
        rows: "12",
        disabled: false,
      },
    ],
    [
      {
        colmd: "4",
        key: "policy_limit",
        type: "label",
        value: "Policy Limit",
        className: customLabel,
      },
      {
        "colmd": "1",
        "key": "policy_limit_symbol",
        "type": "label",
        "value": "$",
        "className": "custom-label"
      }, {
        colmd: "4",
        key: "limit",
        label: "",
        type: "text",
        disabled: false
      }

    ],
    [
      {
        colmd: "4",
        key: "policy_limit_comments",
        type: "label",
        value: "Policy Limit Comments",
        className: customLabel,
      },
      {
        colmd: "4",
        key: "limitNote",
        type: "textarea",
        rows: "12",
        disabled: false,
      },
    ],
  ];
  const close = () => {
    setissubmitted(false);
    setFillAllfields(false);
  }

  const headingLabel = () => {
    return (
      <>
        <div
          id="demographiccasedetails"
          className=""
          style={{ marginBottom: "20px" }}
        >
          <div className="row">
            <div className="col-9">
              <h5
                className="messageTitle"
                style={{
                  width: "60% !important",
                  float: "left",
                }}
              >
                <p
                  style={{
                    fontWeight: "700",
                    fontSize: "16px",
                  }}
                >
                  Please Fill in the below details
                </p>
              </h5>
            </div>
            <div className="col-3 ">
            {demographicSubmitted ? (
            <div className="action-btns "><button class="float-right demobtn2" disabled={true}>
              <i class="fa fa-check-circle" aria-hidden="true"></i>
              {Utility.IsCaseClosed(props.caseDetails["caseStatus"])
                ? "Case Closed"
                : "Submitted"}
            </button></div>
          ) : (
            ""
          )}
            {
              showAddPendingTasksButton && !Utility.IsHearingOfficer() &&
              <div className=" form-btn"><button onClick={updatePendingTaskICaseIClosed} class="float-right btn btn-primary">
                Add To Pending task
              </button></div>
            }
          </div>
</div>


        </div>
        <div className="casestructure">
          <CaseData caseDetails={props.caseDetails} />
        </div>
      </>
    );
  };

  demoGraphicFormConfig[0].fieldSetLabel = headingLabel;
  const [orderUploadFormConfigVar, setOrderUploadFormConfigVar] = useState(
    demoGraphicFormConfig
  );
  const [demographicSubmitted, setdemographicSubmitted] = useState(false);
  const [resolution, setResolution] = useState();

  const [primaryInjury, setPrimaryInjury] = useState({ type: "Primary" });
  const [secondaryInjury, setSecondaryInjury] = useState({ type: "Secondary" });
  const [tertiaryInjury, setTertiaryInjury] = useState({ type: "Tertiary" });
  const [bindDataOnchange, setBindDataOnchange] = useState(false);
  const [injuriesData, setInjuriesData] = useState(false);

  const [current, setCurrent] = useState({
    damageDate: "",
    resolution: "",
    state: "",
    county: "",
    gender: "",
    age: "",
    income: "",
    caseCode: "",
    caseID: "",
    split: "",
    splitRadio: "",
    loss: "",
    awarded: "",
    splitNote: "",
    limit: "",
    limitNote: "",
    primary_injuryType: "",
    primary_partOfBody: "",
    primary_noOfSurgeries: "",
    primary_noOfInjections: "",
    primary_treatmentType: "",
    primary_length: "",
    primary_amountAwarded: "",
    primary_typeOfInjury: "",
    primary_otherTypeOfInjury: "",
    secondary_injuryType: "",
    secondary_partOfBody: "",
    secondary_noOfSurgeries: "",
    secondary_noOfInjections: "",
    secondary_treatmentType: "",
    secondary_length: "",
    secondary_amountAwarded: "",
    secondary_typeOfInjury: "",
    secondary_otherTypeOfInjury: "",
    tertiary_injuryType: "",
    tertiary_injuryOtherType: "",
    tertiary_partOfBody: "",
    tertiary_noOfSurgeries: "",
    tertiary_noOfInjections: "",
    tertiary_treatmentType: "",
    tertiary_length: "",
    tertiary_amountAwarded: "",
    tertiary_typeOfInjury: "",
    tertiary_otherTypeOfInjury: "",
  });
  const [fillAllfields, setFillAllfields] = useState(false);

  const onFormSubmit = (model) => {
    const hearingDetails = JSON.parse(decryptItem("hearingDetails"));
    hearingDetails.pendingAt = 'demographics'
    encryptItem("hearingDetails", JSON.stringify(hearingDetails));

    setIsLoading(true);
    setFillAllfields(false);
    if (model.isValidForm || Object.keys(model.errors).length === 0) {
      if (!disableSubmitButton) {
        console.log("onFormSubmit");
      } else {
        return;
      }

      const body = demographicModel;
      body.damageDate = demographicModel.damageDate
        ? moment(demographicModel.damageDate).format("yyyy-MM-DD")

        : "";

      body.caseCode = `${props.caseDetails["caseCode"]}`;
      body.caseID = `${props.caseDetails["caseCode"]}`;
      if (demographicModel?.resolution === "Unsettled") {
        body.loss = "";
        body.awarded = "";
        body.split = "";
        body.splitNote = "";
        body.limit = "";
        body.limitNote = "";
      }
      if (demographicModel?.injuries != null && demographicModel?.injuries.length > 1) {
        const secondaryIndex = demographicModel?.injuries
          ?.map((e) => e.type.toLowerCase())
          .indexOf("secondary");
        if (
          secondaryIndex !== null &&
          secondaryIndex !== undefined &&
          secondaryIndex !== -1 &&
          document.getElementsByClassName(secondaryinformationdiv)[0].style
            .display === "none"
        ) {
          body.injuries.splice(secondaryIndex, 1);
        }
      }
      if (demographicModel?.injuries != null && demographicModel?.injuries.length > 2) {
        const tertiaryIndex = demographicModel?.injuries
          ?.map((e) => e.type.toLowerCase())
          .indexOf("tertiary");
        if (
          tertiaryIndex !== null &&
          tertiaryIndex !== undefined &&
          tertiaryIndex !== -1 &&
          document.getElementsByClassName(tertiaryinformationdiv)[0].style
            .display === "none"
        ) {
          body.injuries.splice(tertiaryIndex, 1);
        }
      }
      body.userCode = `${decryptItem("userid")}`;

      axios
        .post(CREATE_DEMOGRPAHICS, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          window.scrollTo(0, 0);
          const config = {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          setFillAllfields(false);

          setDemographicModel({ ...demographicModel, id: res.data.id });
          setissubmitted(true);
          if (isSyncBack) {
            body.hearingDate = props.caseDetails.hearingDate;
            axios
              .post(UPDATE_CMSDEMOGRPAHICS, body, config)
              .then((res2) => {
                if (res2.status === 200) {
                  // // TO DO document why this block is empty                
                }
              })
              .catch((error) => {
                setdisplayAlertError(false);
                console.log(error);
              });
            // //=====================
          }

          axios
            .post(
              `${UPDATE_PENDINGTASKS}?pendingAt=demographics&caseCode=${props.caseDetails["caseCode"]}&hearingCode=${props.caseDetails["hearingCode"]}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((res_PendingTask) => {
              if (Utility.IsHearingOfficer()) {
                get(
                  `${GET_PENDINGCOUNTFORNEUTRAL}?neutralEmail=${decryptItem("neutralsEmail")}&userCode=${decryptItem("jCode"
                  )}`,
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                ).then((resp2) => {
                  console.log("pendingTaskCount", resp2);
                  localStorage.setItem("pendingTaskCount", `${resp2.data}`);
                });
              }
            })
            .catch((error) => {
              setdisplayAlertError(true);
              console.log(error);
            });
        })
        .catch((error) => {
          setdisplayAlertError(true);
          console.log(error);
        });

      setdemographicSubmitted(true);
    } else {
      setTimeout(() => {
        setFillAllfields(true);
      }, 500);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };
  const updatedisplayAlert = (e) => {
    setdisplayAlertError(false);
  };

  useEffect(() => {
    if (document.getElementsByClassName("text-danger").length > 0) {
      let element = document.getElementsByClassName("text-danger")[0].closest('.row');
      let header = document.getElementById('casedetailtabs-tab-schedulingInformation').closest('ul')
      let headerOffset = header.getBoundingClientRect().bottom;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });

    }

  }, [fillAllfields])

  const getDemographicdata = () => {

    setIsLoading(true);

    const url = `${GET_DEMOGRPAHICS}/${props.caseDetails["caseCode"]}`;

    axios
      .get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        
        if (res?.data?.demogrpahic?.length === 0 && Utility.IsCaseClosed(props.caseDetails["caseStatus"])) {
          //show add to pending task button
          checkAddToPendingTaskIfCaseIsCLosed();
        }
        const temp = orderUploadFormConfigVar;
        const optionsAge = [];
        setdemographicSubmitted(
          Utility.IsCaseClosed(props.caseDetails["caseStatus"])
        );
        optionsAge.push({
          key: "--Select Age of Plaintiff / Injury Party--",
          label: "--Select Age of Plaintiff / Injury Party--",
          value: "",
        });
        const ageList = res.data.demographicsMetadata.ageList.sort((a, b) =>
          a.value.localeCompare(b.value)
        );
        ageList.forEach((element,index) => {
          optionsAge.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });

        temp[1].columns[0].rows[5][1].options = optionsAge; //// Age of Plaintiff / Injury Party

        const optionsGender = [];
        optionsGender.push({
          key: "--Select Gender of Plaintiff / Injured Party--",
          label: "--Select Gender of Plaintiff / Injured Party--",
          value: "",
        });
        const genderList = res.data.demographicsMetadata.genderList.sort(
          (a, b) => a.value.localeCompare(b.value)
        );
        genderList.forEach((element,index) => {
          optionsGender.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[1].columns[0].rows[4][1].options = optionsGender; //// Gender of Plaintiff / Injury Party

        const optionsincome = [];
        optionsincome.push({
          key: "--Select Approx Income of Plaintiff--",
          label: "--Select Approx Income of Plaintiff--",
          value: "",
        });
        const incomeList = res.data.demographicsMetadata.incomeList.sort(
          (a, b) => a.value.localeCompare(b.value)
        );
        incomeList.forEach((element,index) => {
          optionsincome.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[1].columns[0].rows[6][1].options = optionsincome; //// Approx Income of Plaintiff
        const stateList = [];
        stateList.push({
          key: "--Select Venue State--",
          label: "--Select Venue State--",
          value: "",
        });
        const stateDataList = res.data.demographicsMetadata.stateList.sort(
          (a, b) => a.key.localeCompare(b.key)
        );
        stateDataList.forEach((element,index) => {
          stateList.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[1].columns[0].rows[2][1].options = stateList; //// Venue State

        const newyorkCity = stateList.filter((val) =>
          val.key.includes("New York")
        )
          ? stateList.filter((val) => val.key.includes("New York"))[0].value
          : "";
        temp[1].columns[0].rows[2][1].value = newyorkCity;
        setCurrent({ ...current, state: newyorkCity });
        const countyList = [];
        const countyDataList = res.data.demographicsMetadata.countyList.sort(
          (a, b) => a.key.localeCompare(b.key)
        );
        
        countyDataList.forEach((element,index) => {
          countyList.push({
            key: element.dependentValue,
            label: element.key,
            value: element.value,
          });
        });

        setCountyData(countyList);

        const filterCountyByNewYorkCity = countyList.filter((val) =>
         val.key == newyorkCity
        );
        filterCountyByNewYorkCity.unshift({
          key: selectCounty,
          label: selectCounty,
          value: "",
        });
        temp[1].columns[0].rows[3][1].options = filterCountyByNewYorkCity; //// Venue County

        const injuryListPrimary = [];
        injuryListPrimary.push({
          key: "--Select Primary Injury--",
          label: "--Select Primary Injury--",
          value: "",
        });
        const injuryList = res.data.demographicsMetadata.injuryList.sort(
          (a, b) => a.key.localeCompare(b.key)
        );
        
        injuryList.forEach((element,index) => {
          injuryListPrimary.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[2].columns[0].rows[0][1].options = injuryListPrimary; //// Primary Injury

        const injuryListSecondary = [];
        injuryListSecondary.push({
          key: "--Select Secondary Injury--",
          label: "--Select Secondary Injury--",
          value: "",
        });
        injuryList.forEach((element,index) => {
          injuryListSecondary.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[3].columns[0].rows[1][1].options = injuryListSecondary; //// Secondary Injury

        const injuryListTertiary = [];
        injuryListTertiary.unshift({
          key: "--Select Tertiary Injury--",
          label: "--Select Tertiary Injury--",
          value: "",
        });
        injuryList.forEach((element,index) => {
          injuryListTertiary.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[4].columns[0].rows[1][1].options = injuryListTertiary; //// Tertiary Injury
        const injuryTypeList = res.data.demographicsMetadata.injuryTypeList.sort(
          (a, b) => a.key.localeCompare(b.key)
        );

        const typesOfInjuryList = [];
        typesOfInjuryList.push({
          key: "--Select Primary Types Of Injury--",
          label: "--Select Primary Types Of Injury--",
          value: "",
        });
        injuryTypeList.forEach((element,index) => {
          typesOfInjuryList.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        const typesOfInjuryListSeconadry = [];
        typesOfInjuryListSeconadry.push({
          key: "--Select Secondary Types Of Injury--",
          label: "--Select Secondary Types Of Injury--",
          value: "",
        });
        injuryTypeList.forEach((element,index) => {
          typesOfInjuryListSeconadry.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        const typesOfInjuryListTertiary = [];
        typesOfInjuryListTertiary.push({
          key: "--Select Tertiary Types Of Injury--",
          label: "--Select Tertiary Types Of Injury--",
          value: "",
        });
        injuryTypeList.forEach((element,index) => {
          typesOfInjuryListTertiary.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[4].columns[0].rows[8][1].options = typesOfInjuryListTertiary; //// typesOfInjuryList
        temp[3].columns[0].rows[8][1].options = typesOfInjuryListSeconadry; //// typesOfInjuryList
        temp[2].columns[0].rows[7][1].options = typesOfInjuryList; //// typesOfInjuryList

        const bodyPartListPrimary = [];
        bodyPartListPrimary.unshift({
          key: "--Select Part of Body Injured(Primary Injury)--",
          label: "--Select Part of Body Injured(Primary Injury)--",
          value: "",
        });
        let bodyPartList = [];
        for (
          let i = 0;
          i < res.data.demographicsMetadata.bodyPartList.length;
          i++
        ) {
          if (res.data.demographicsMetadata.bodyPartList[i].key !== null) {
            bodyPartList.push(res.data.demographicsMetadata.bodyPartList[i]);
          }
        }
        bodyPartList = bodyPartList.sort((a, b) => a.key.localeCompare(b.key));
        bodyPartList.forEach((element,index) => {
          bodyPartListPrimary.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[2].columns[0].rows[1][1].options = bodyPartListPrimary; //// Part of Body Injured

        const bodyPartListSecondary = [];
        bodyPartListSecondary.push({
          key: "--Select Part of Body Injured(Secondary Injury)--",
          label: "--Select Part of Body Injured(Secondary Injury)--",
          value: "",
        });
        bodyPartList.forEach((element,index) => {
          bodyPartListSecondary.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[3].columns[0].rows[2][1].options = bodyPartListSecondary; //// Part of Body Injured(Secondary)

        const bodyPartListTertiary = [];
        bodyPartListTertiary.push({
          key: "--Select Part of Body Injured(Tertiary Injury)--",
          label: "--Select Part of Body Injured(Tertiary Injury)--",
          value: "",
        });
        bodyPartList.forEach((element,index) => {
          bodyPartListTertiary.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[4].columns[0].rows[2][1].options = bodyPartListTertiary; //// Part of Body Injured(Tertiary)

        const treatmentListPrimary = [];
        treatmentListPrimary.unshift({
          key: "--Select Treatment Type(Primary Injury)--",
          label: "--Select Treatment Type(Primary Injury)--",
          value: "",
        });
        const treatmentList = res.data.demographicsMetadata.treatmentList.sort(
          (a, b) => a.key.localeCompare(b.key)
        );
        treatmentList.forEach((element,index) => {
          treatmentListPrimary.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[2].columns[0].rows[4][1].options = treatmentListPrimary; //// Primary - Treatment Type

        const treatmentListSecondary = [];
        treatmentListSecondary.unshift({
          key: "--Select Treatment Type(Secondary Injury)--",
          label: "--Select Treatment Type(Secondary Injury)--",
          value: "",
        });
        treatmentList.forEach((element,index) => {
          treatmentListSecondary.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[3].columns[0].rows[5][1].options = treatmentListSecondary; //// Secondary - Treatment Type

        const treatmentListTertiary = [];
        treatmentListTertiary.unshift({
          key: "--Select Treatment Type(Tertiary Injury)--",
          label: "--Select Treatment Type(Tertiary Injury)--",
          value: "",
        });
        treatmentList.forEach((element,index) => {
          treatmentListTertiary.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[4].columns[0].rows[5][1].options = treatmentListTertiary; //// Tertiary - Treatment Type

        const resolutionList = [];
        resolutionList.push({
          key: "--Select Resolution--",
          label: "--Select Resolution--",
          value: "",
        });
        const resolutionDataList =
          res.data.demographicsMetadata.resolutionList.sort((a, b) =>
            a.key.localeCompare(b.key)
          );
        resolutionDataList.forEach((element,index) => {
          resolutionList.push({
            key: element.key.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g,function(c){return c.toUpperCase()}),
            label: element.key.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g,function(c){return c.toUpperCase()}),
            value: element.value,
          });
        });
        temp[1].columns[0].rows[0][1].options = resolutionList; //// Resolution

        //// if Casetype is Arbitration
        if (isCasetypeArbitration) {
          if (document.querySelector("div.control-resolution") !== null) {
            const resolutionElement = document
              .querySelector("div.control-resolution")
              .closest("div.row");
            if (resolutionElement) {
              resolutionElement.style.display = "none";
              delete temp[1].columns[0].rows[0][1].validators;
            }
          }
        }

        temp[1].columns[0].rows[1][1].maxDate = yesterday; //// set up max date
        const treatmentLengthListPrimary = [];
        treatmentLengthListPrimary.unshift({
          key: "--Select Length of Treatment(Primary Injury)--",
          label: "--Select Length of Treatment(Primary Injury)--",
          value: "",
        });
        const treatmentLengthList =
          res.data.demographicsMetadata.treatmentLengthList.sort();
        treatmentLengthList.forEach((element,index) => {
          treatmentLengthListPrimary.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });

        temp[2].columns[0].rows[5][1].options = treatmentLengthListPrimary; //// Treatment Length

        const treatmentLengthListSecondary = [];
        treatmentLengthListSecondary.unshift({
          key: "--Select Length of Treatment(Secondary Injury)--",
          label: "--Select Length of Treatment(Secondary Injury)--",
          value: "",
        });
        treatmentLengthList.forEach((element,index) => {
          treatmentLengthListSecondary.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[3].columns[0].rows[6][1].options = treatmentLengthListSecondary; //// Treatment Length

        const treatmentLengthListTertiary = [];
        treatmentLengthListTertiary.unshift({
          key: "--Select Length of Treatment(Tertiary Injury)--",
          label: "--Select Length of Treatment(Tertiary Injury)--",
          value: "",
        });
        treatmentLengthList.forEach((element,index) => {
          treatmentLengthListTertiary.push({
            key: element.key,
            label: element.key,
            value: element.value,
          });
        });
        temp[4].columns[0].rows[6][1].options = treatmentLengthListTertiary; //// Treatment Length

        const latestDemographicdata =
          res.data?.demogrpahic.length === 0
            ? null
            : res.data?.demogrpahic?.[res.data?.demogrpahic?.length - 1];

        if (
          !isCasetypeArbitration &&
          res.data?.demogrpahic.length !== 0 &&
          res.data?.demogrpahic?.[res.data?.demogrpahic?.length - 1]
            .resolution &&
          res.data?.demogrpahic?.[res.data?.demogrpahic?.length - 1]
            .resolution === "Unsettled"
        ) {
          temp[1].columns[0].rows.splice(7);
        }

        document
          .getElementsByClassName("control-delete_secondary_inury")[0]
          .closest(".row").style.display = "none"; //// secondary not in use
        document
          .getElementsByClassName("control-delete_inury")[0]
          .closest(".row").style.display = "none"; //// tertiary not in use

        if (res.data?.demogrpahic.length > 0) {
          const els = document.getElementsByClassName(tertiaryinformationdiv);
          Array.from(els).forEach((item) => {
            item.style.display =
              latestDemographicdata.injuries !== null &&
                latestDemographicdata.injuries.length === 3
                ? "block"
                : "none";
          });

          const els1 = document.getElementsByClassName(secondaryinformationdiv);
          Array.from(els1).forEach((item) => {
            item.style.display =
              latestDemographicdata.injuries !== null &&
                latestDemographicdata.injuries.length >= 2
                ? "block"
                : "none";
          });

          //// add injury button in primary
          temp[3].columns[0].rows[8][0].hide =
            latestDemographicdata.injuries !== null &&
            latestDemographicdata.injuries.length > 2;
          document
            .getElementsByClassName("control-add_secondary_injury")[0]
            .closest(".row").style.display =
            latestDemographicdata.injuries !== null &&
              latestDemographicdata.injuries.length > 2
              ? "none"
              : "block";
          //// add injury button in secondary
          temp[2].columns[0].rows[8][0].hide =
            latestDemographicdata.injuries !== null &&
            latestDemographicdata.injuries.length >= 2;
          document
            .getElementsByClassName("control-add_secondary_injury")[0]
            .closest(".row").style.display =
            latestDemographicdata.injuries !== null &&
              latestDemographicdata.injuries.length >= 2
              ? "none"
              : "block";

          if (
            latestDemographicdata.injuries &&
            latestDemographicdata.injuries[0]?.injuryOtherType
          ) {
            temp[2].columns[0].rows[0][2].hide = false;
            temp[2].columns[0].rows[0][2].validators[0].value = true;
          } else {
            temp[2].columns[0].rows[0][2].hide = true;
            temp[2].columns[0].rows[0][2].validators[0].value = false;
          }
          if (
            latestDemographicdata.injuries &&
            latestDemographicdata.injuries[0]?.otherPartOfBody
          ) {
            temp[2].columns[0].rows[1][2].hide = false;
            temp[2].columns[0].rows[1][2].validators[0].value = true;
          } else {
            temp[2].columns[0].rows[1][2].hide = true;
            temp[2].columns[0].rows[1][2].validators[0].value = false;
          }
          if (
            latestDemographicdata.injuries &&
            latestDemographicdata.injuries[0]?.otherTypeOfInjury
          ) {
            temp[2].columns[0].rows[7][2].hide = false;
            temp[2].columns[0].rows[7][2].validators[0].value = true;
          } else {
            temp[2].columns[0].rows[7][2].hide = true;
            temp[2].columns[0].rows[7][2].validators[0].value = false;
          }

          if (
            latestDemographicdata.injuries !== null &&
            latestDemographicdata.injuries.length >= 2
          ) {
            temp[2].columns[0].rows[8][0].hide = true;
            temp[3].columns[0].rows[0][0].hide = true;

            temp[3].columns[0].rows[1][0].hide = false;
            temp[3].columns[0].rows[1][1].hide = false;
            temp[3].columns[0].rows[2][0].hide = false;
            temp[3].columns[0].rows[2][1].hide = false;
            temp[3].columns[0].rows[3][0].hide = false;
            temp[3].columns[0].rows[3][1].hide = false;
            temp[3].columns[0].rows[4][0].hide = false;
            temp[3].columns[0].rows[4][1].hide = false;
            temp[3].columns[0].rows[5][0].hide = false;
            temp[3].columns[0].rows[5][1].hide = false;
            temp[3].columns[0].rows[6][0].hide = false;
            temp[3].columns[0].rows[6][1].hide = false;
            temp[3].columns[0].rows[7][0].hide = false;
            temp[3].columns[0].rows[7][1].hide = false;
            temp[3].columns[0].rows[8][0].hide = false;
            temp[3].columns[0].rows[8][1].hide = false;
            if (
              latestDemographicdata.injuries &&
              latestDemographicdata.injuries[1]?.injuryOtherType
            ) {
              temp[3].columns[0].rows[1][2].hide = false;
              temp[3].columns[0].rows[1][2].validators[0].value = true;
            } else {
              temp[3].columns[0].rows[1][2].hide = true;
              temp[3].columns[0].rows[1][2].validators[0].value = false;
            }

            if (
              latestDemographicdata.injuries &&
              latestDemographicdata.injuries[1]?.otherTypeOfInjury
            ) {
              temp[3].columns[0].rows[8][2].hide = false;
              temp[3].columns[0].rows[8][2].validators[0].value = true;
            } else {
              temp[3].columns[0].rows[8][2].hide = true;
              temp[3].columns[0].rows[8][2].validators[0].value = false;
            }

            if (
              latestDemographicdata.injuries &&
              latestDemographicdata.injuries[1]?.otherPartOfBody
            ) {
              temp[3].columns[0].rows[2][2].hide = false;
              temp[3].columns[0].rows[2][2].validators[0].value = true;
            } else {
              temp[3].columns[0].rows[2][2].hide = true;
              temp[3].columns[0].rows[2][2].validators[0].value = false;
            }
            const element = document.getElementsByClassName("SecondaryDelete");
            element[0].style.display = "block";
          }
          if (
            latestDemographicdata.injuries !== null &&
            latestDemographicdata.injuries.length > 2
          ) {
            temp[3].columns[0].rows[9][0].hide = true;
            temp[4].columns[0].rows[0][0].hide = true;

            temp[4].columns[0].rows[1][0].hide = false;
            temp[4].columns[0].rows[1][1].hide = false;
            temp[4].columns[0].rows[2][0].hide = false;
            temp[4].columns[0].rows[2][1].hide = false;
            temp[4].columns[0].rows[3][0].hide = false;
            temp[4].columns[0].rows[3][1].hide = false;
            temp[4].columns[0].rows[4][0].hide = false;
            temp[4].columns[0].rows[4][1].hide = false;
            temp[4].columns[0].rows[5][0].hide = false;
            temp[4].columns[0].rows[5][1].hide = false;
            temp[4].columns[0].rows[6][0].hide = false;
            temp[4].columns[0].rows[6][1].hide = false;
            temp[4].columns[0].rows[7][0].hide = false;
            temp[4].columns[0].rows[7][1].hide = false;
            temp[4].columns[0].rows[8][0].hide = false;
            temp[4].columns[0].rows[8][1].hide = false;
            if (
              latestDemographicdata.injuries &&
              latestDemographicdata.injuries[2]?.injuryOtherType
            ) {
              temp[4].columns[0].rows[1][2].hide = false;
              temp[4].columns[0].rows[1][2].validators[0].value = true;
            } else {
              temp[4].columns[0].rows[1][2].hide = true;
              temp[4].columns[0].rows[1][2].validators[0].value = false;
            }

            if (
              latestDemographicdata.injuries &&
              latestDemographicdata.injuries[2]?.otherTypeOfInjury
            ) {
              temp[4].columns[0].rows[8][2].hide = false;
              temp[4].columns[0].rows[8][2].validators[0].value = true;
            } else {
              temp[4].columns[0].rows[8][2].hide = true;
              temp[4].columns[0].rows[8][2].validators[0].value = false;
            }

            if (
              latestDemographicdata.injuries &&
              latestDemographicdata.injuries[2]?.otherPartOfBody
            ) {
              temp[4].columns[0].rows[2][2].hide = false;
              temp[4].columns[0].rows[2][2].validators[0].value = true;
            } else {
              temp[4].columns[0].rows[2][2].hide = true;
              temp[4].columns[0].rows[2][2].validators[0].value = false;
            }

            document.getElementsByClassName(
              "SecondaryDelete"
            )[0].style.display = "none";
          }
        } else {
          document.getElementsByClassName(
            secondaryinformationdiv
          )[0].style.display = "none";
          document.getElementsByClassName(
            tertiaryinformationdiv
          )[0].style.display = "none";
        }
        //// if option is set to other.

          if(latestDemographicdata)
          {
            latestDemographicdata.loss= latestDemographicdata?.loss? parseFloat(latestDemographicdata?.loss?.replaceAll(",","")).toFixed(2): ""
            latestDemographicdata.awarded= latestDemographicdata?.awarded? parseFloat(latestDemographicdata?.awarded?.replaceAll(",","")).toFixed(2): ""
            latestDemographicdata.limit= latestDemographicdata?.limit? latestDemographicdata?.limit.split(".")[0]: ""
            
            let filterCountyByState = [];
            if (latestDemographicdata.state!==newyorkCity) {
              temp[1].columns[0].rows[3][1].options = [];
              filterCountyByState = countyList.filter((val) =>
                val.key == latestDemographicdata.state
              );
              filterCountyByState.unshift({
                key: selectCounty,
                label: selectCounty,
                value: "",
              });
              temp[1].columns[0].rows[3][1].options = filterCountyByState;
            }

        }

        setDemographicModel(latestDemographicdata);
        setUpdateSateOfDemographics(temp);
        setInjuriesData(true);
        bindDeleteButton();
        setIsLoading(false);
      })
      .catch((error) => {
        setdisplayAlertError(true);
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (injuriesData) {
      if (
        demographicModel &&
        demographicModel.injuries !== undefined &&
        demographicModel.injuries !== null
      ) {
        setPrimaryInjury(
          demographicModel.injuries[0]
            ? demographicModel.injuries[0]
            : { type: "Primary" }
        );
        setSecondaryInjury(
          demographicModel.injuries[1]
            ? demographicModel.injuries[1]
            : { type: "Secondary" }
        );
        setTertiaryInjury(
          demographicModel.injuries[2]
            ? demographicModel.injuries[2]
            : { type: "Tertiary" }
        );
      }
      setInjuriesData(false);
    }
  }, [injuriesData]);

  useEffect(() => {
    setIsLoading(true);
    document.getElementsByClassName(secondaryinformationdiv)[0].style.display =
      "none";
    document.getElementsByClassName(tertiaryinformationdiv)[0].style.display =
      "none";
    //// if Casetype is Arbitration
    if (isCasetypeArbitration) {
      const resolutionElement = document
        .querySelector("div.control-resolution")
        .closest("div.row");
      if (resolutionElement) {
        resolutionElement.style.display = "none";
      }
    }
    setPlaceholder();
    setIsLoading(false);
    getDemographicdata();
  }, []);

  const setPlaceholder = () => {
    const inputLoss = document.getElementsByName("loss")[0];
    if (inputLoss) {
      inputLoss.setAttribute("placeholder", "$0.00");
    }

    const inputAwarded = document.getElementsByName("awarded")[0];
    if (inputAwarded) {
      inputAwarded.setAttribute("placeholder", "$0.00");
    }
    const inputLimit = document.getElementsByName("limit")[0];
    if (inputLimit) {
      inputLimit.setAttribute("placeholder", "$0");
    }
  };

  useEffect(() => {
    if (updateSateOfDemographics) {
      setOrderUploadFormConfigVar(updateSateOfDemographics);
      setAfterMasterDataBind(true);
      setInitalDatBind(true)
    }
  }, [updateSateOfDemographics]);

  useEffect(() => {
    //// binding initil data
    if (afterMasterDataBind) {
      dataBinding();
      setInitalDatBind(false)
      setAfterMasterDataBind(false);
    }
  }, [afterMasterDataBind]);

  const dataBinding = () => {
    setIsLoading(true);
    if (demographicModel) {
      const tempCurrent = {
        damageDate: demographicModel.damageDate
          ? moment(demographicModel.damageDate).format("yyyy-MM-DD")
          : "",
        resolution: isCasetypeArbitration ? "" : demographicModel.resolution,
        state: demographicModel.state,
        county: demographicModel.county,
        gender: demographicModel.gender,
        age: demographicModel.age,
        income: demographicModel.income,
        caseCode: demographicModel.caseCode,
        caseID: demographicModel.caseID,
        split: demographicModel.split,
        splitRadio: demographicModel.split,
        loss: demographicModel.loss,
        awarded: demographicModel.awarded,
        splitNote: demographicModel.splitNote,
        limit: demographicModel.limit,
        limitNote: demographicModel.limitNote,
        primary_injuryType:
          demographicModel.injuries && demographicModel.injuries[0]?.injuryType,
        primary_injuryOtherType:
          demographicModel.injuries &&
          demographicModel.injuries[0]?.injuryOtherType,
        primary_otherPartOfBody:
          demographicModel.injuries &&
          demographicModel.injuries[0]?.otherPartOfBody,
        primary_partOfBody:
          demographicModel.injuries && demographicModel.injuries[0]?.partOfBody,
        primary_noOfSurgeries:
          demographicModel.injuries &&
          demographicModel.injuries[0]?.noOfSurgeries,
        primary_noOfInjections:
          demographicModel.injuries &&
          demographicModel.injuries[0]?.noOfInjections,
        primary_treatmentType:
          demographicModel.injuries &&
          demographicModel.injuries[0]?.treatmentType,
        primary_length:
          demographicModel.injuries && demographicModel.injuries[0]?.length,
        primary_amountAwarded:
          demographicModel.injuries &&
          demographicModel.injuries[0]?.amountAwarded,
        primary_otherTypeOfInjury:
          demographicModel.injuries &&
          demographicModel.injuries[0]?.otherTypeOfInjury,
        primary_typeOfInjury:
          demographicModel.injuries &&
          demographicModel.injuries[0]?.typeOfInjury,

        secondary_injuryType:
          demographicModel.injuries && demographicModel.injuries[1]?.injuryType,
        secondary_injuryOtherType:
          demographicModel.injuries &&
          demographicModel.injuries[1]?.injuryOtherType,
        secondary_otherPartOfBody:
          demographicModel.injuries &&
          demographicModel.injuries[1]?.otherPartOfBody,
        secondary_partOfBody:
          demographicModel.injuries && demographicModel.injuries[1]?.partOfBody,
        secondary_noOfSurgeries:
          demographicModel.injuries &&
          demographicModel.injuries[1]?.noOfSurgeries,
        secondary_noOfInjections:
          demographicModel.injuries &&
          demographicModel.injuries[1]?.noOfInjections,
        secondary_treatmentType:
          demographicModel.injuries &&
          demographicModel.injuries[1]?.treatmentType,
        secondary_length:
          demographicModel.injuries && demographicModel.injuries[1]?.length,
        secondary_amountAwarded:
          demographicModel.injuries &&
          demographicModel.injuries[1]?.amountAwarded,
        secondary_otherTypeOfInjury:
          demographicModel.injuries &&
          demographicModel.injuries[1]?.otherTypeOfInjury,
        secondary_typeOfInjury:
          demographicModel.injuries &&
          demographicModel.injuries[1]?.typeOfInjury,



        tertiary_injuryType:
          demographicModel.injuries && demographicModel.injuries[2]?.injuryType,
        tertiary_injuryOtherType:
          demographicModel.injuries &&
          demographicModel.injuries[2]?.injuryOtherType,
        tertiary_otherPartOfBody:
          demographicModel.injuries &&
          demographicModel.injuries[2]?.otherPartOfBody,
        tertiary_partOfBody:
          demographicModel.injuries && demographicModel.injuries[2]?.partOfBody,
        tertiary_noOfSurgeries:
          demographicModel.injuries &&
          demographicModel.injuries[2]?.noOfSurgeries,
        tertiary_noOfInjections:
          demographicModel.injuries &&
          demographicModel.injuries[2]?.noOfInjections,
        tertiary_treatmentType:
          demographicModel.injuries &&
          demographicModel.injuries[2]?.treatmentType,
        tertiary_length:
          demographicModel.injuries && demographicModel.injuries[2]?.length,
        tertiary_amountAwarded:
          demographicModel.injuries &&
          demographicModel.injuries[2]?.amountAwarded,
        tertiary_otherTypeOfInjury:
          demographicModel.injuries &&
          demographicModel.injuries[2]?.otherTypeOfInjury,
        tertiary_typeOfInjury:
          demographicModel.injuries &&
          demographicModel.injuries[2]?.typeOfInjury,

      };
      if (initalDatBind) {
        setCurrent(tempCurrent);
      }
    } else {
      setDemographicModel({ ...demographicModel, state: current.state });
    }
    setBindDataOnchange(false);
    setPlaceholder();
    setIsLoading(false);
  };

  const errorText_Injuction_Surgeries =
    "# Should not exceed 10 and should not be a negative number";
  const errorText_AmountAwarded =
    "# Should not exceed 100 and should not be a negative number";

  useEffect(() => {
    window.addEventListener("keypress", (e) => {
      const key = e.target.name;
      if (key && (
        key.includes("Injection") ||
        key.includes("Surgeries") ||
        key.includes("amountAwarded") ||
        key === "split" || key === "splitRadio")
      ) {
        if (["e", "E", "+", "-", "."].includes(e.key)) {
          e.preventDefault();
          return;
        }
        const re = /^([0-9]\d*)$/;
        if (e.key && !re.test(e.key)) {
          e.preventDefault();
          return;
        }
      }
    });
  });

  const validate_Injuction_Surgeries = (key, value) => {
    if (
      (key.includes("Injection") || key.includes("Surgeries")) &&
      (parseInt(value) < 0 || parseInt(value) > 10)
    ) {
      setDisableSubmitButton(true);
      return false;
    } else {
      setDisableSubmitButton(false);
      return true;
    }
  };

  const validate_AmountAwarded = (key, value) => {
    if (
      key.includes("amountAwarded") &&
      (parseInt(value) < 0 || parseInt(value) > 100)
    ) {
      setDisableSubmitButton(true);
      return false;
    } else {
      setDisableSubmitButton(false);
      return true;
    }
  };
  const diableSubmitButton_Validate = () => {

    setDisableSubmitButton(true);
    return false;
  };
  const validate_LiabilitySplit = (key, value) => {
    if ((key === "split" || key === "splitRadio") && value) {
      if ((isNaN(value) || !isNaN(value)) && value !== _0Liability && value !== "unknown" && parseInt(value) > 100) {
        diableSubmitButton_Validate();
        return false;
      } else {
        setDisableSubmitButton(false);
        return true;
      }
    }
    else {
      return true;
    }
  };
  const customeValidation = [
    {
      key: "split",
      validator: validate_LiabilitySplit,
      message: "invalid liability split",
    },
    {
      key: "limit",
      validator: (keyname, value) => {
        if (keyname === "limit" && value) {
          let decimallimitValue = value
          decimallimitValue = decimallimitValue.replace(/[^0-9 ]/g, '')
          if (decimallimitValue?.length > 15) {
            setDisableSubmitButton(true);
            return false;
          }
          else {
            setDisableSubmitButton(false);
            return true;
          }
        }
        else {
          return true;
        }
      },
      message:
        "Maximum 15 digits are allowed",
    },
    {
      key: "loss",
      validator: (keyname, value) => {
        if (keyname === "loss" && value) {
          let decimalLossValue = value.split('.')[0]
          decimalLossValue = decimalLossValue.replace(/[^0-9 ]/g, '')
          let isValidLoss = /^\d{1,3}(,\d{3})*(\.?\d\d)?$/i.test(value.replace(/[^\d\.]/g, "").replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          if (decimalLossValue.length > 15) {
            diableSubmitButton_Validate();
          }
          else if (value.split('.')?.length === 2 && value.split('.')[1].length > 2) {
            diableSubmitButton_Validate();
          }
          else if (demographicModel?.loss && !isValidLoss) {
            diableSubmitButton_Validate();
          }
          else {
            setDisableSubmitButton(false);
            return true;
          }
        }
        else {
          return true;
        }

      },
      message: "Maximum 15 digits and two decimal places are allowed.",
    },

    {
      key: "awarded",
      validator: (keyname, value) => {
        if (keyname === "awarded" && value) {
          let decimalawardedValue = value.split('.')[0]
          decimalawardedValue = decimalawardedValue.replace(/[^0-9 ]/g, '')
          let isValidAwarded = /^\d{1,3}(,\d{3})*(\.?\d\d)?$/i.test(value.replace(/[^\d\.]/g, "").replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ","))
          if (decimalawardedValue?.length > 15) {
            diableSubmitButton_Validate();
          }
          else if (value.split('.')?.length === 2 && value.split('.')[1].length > 2) {
            diableSubmitButton_Validate();
          }
          else if (demographicModel?.awarded && !(isValidAwarded)) {
            diableSubmitButton_Validate();
          }
          else {
            setDisableSubmitButton(false);
            return true;
          }
        }
        else {
          return true;
        }
      },
      message: "Maximum 15 digits and two decimal places are allowed.",
    },
    {
      key: "primary_noOfSurgeries",
      validator: validate_Injuction_Surgeries,
      message: errorText_Injuction_Surgeries,
    },
    {
      key: "primary_noOfInjections",
      validator: validate_Injuction_Surgeries,
      message: errorText_Injuction_Surgeries,
    },
    {
      key: "primary_amountAwarded",
      validator: validate_AmountAwarded,
      message: errorText_AmountAwarded,
    },

    {
      key: "secondary_noOfSurgeries",
      validator: validate_Injuction_Surgeries,
      message: errorText_Injuction_Surgeries,
    },
    {
      key: "secondary_noOfInjections",
      validator: validate_Injuction_Surgeries,
      message: errorText_Injuction_Surgeries,
    },
    {
      key: "secondary_amountAwarded",
      validator: validate_AmountAwarded,
      message: errorText_AmountAwarded,
    },

    {
      key: "tertiary_noOfSurgeries",
      validator: validate_Injuction_Surgeries,
      message: errorText_Injuction_Surgeries,
    },
    {
      key: "tertiary_noOfInjections",
      validator: validate_Injuction_Surgeries,
      message: errorText_Injuction_Surgeries,
    },
    {
      key: "tertiary_amountAwarded",
      validator: validate_AmountAwarded,
      message: errorText_AmountAwarded,
    },
  ];
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("")


  const [secondaryInujryRemove, setSecondaryInujryRemove] = useState(undefined)
  const [tertiaryInjuryRemove, setTertiaryInjuryRemove] = useState(undefined)
  const closeConfirmDialog = (value) => {
    setOpenConfirmDialog(false);
    setIsConfirmedDelete(value);
    setDeleteMessage("");
  }

  useEffect(() => {
    if (isConfirmedDelete) {
      if (secondaryInujryRemove) {
        document.getElementsByClassName(secondaryinformationdiv)[0].style.display =
          "none";
        document.getElementsByClassName(secondaryinformationdiv)[1].style.display =
          "none";
        orderUploadFormConfigVar[2].columns[0].rows[8][0].hide = false;
        document
          .getElementsByClassName("control-add_secondary_injury")[0]
          .closest(".row").style.display = "block";
        orderUploadFormConfigVar[3].columns[0].rows[0][0].hide = true;

        orderUploadFormConfigVar[3].columns[0].rows[1][0].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[1][1].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[1][2].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[1][2].validators[0].value = false;
        orderUploadFormConfigVar[3].columns[0].rows[2][0].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[2][1].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[2][2].validators[0].value = false;
        orderUploadFormConfigVar[3].columns[0].rows[2][2].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[3][0].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[3][1].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[4][0].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[4][1].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[5][0].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[5][1].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[6][0].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[6][1].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[7][0].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[7][1].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[8][0].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[9][0].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[8][1].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[8][2].hide = true;
        orderUploadFormConfigVar[3].columns[0].rows[8][2].validators[0].value = false;
        setBindDataOnchange(true);
        setIsSecondaryDeleted(true);
        setSecondaryInujryRemove(undefined);
      }

      if (tertiaryInjuryRemove) {
        document.getElementsByClassName(tertiaryinformationdiv)[0].style.display =
          "none";
        document.getElementsByClassName(tertiaryinformationdiv)[1].style.display =
          "none";
        document.getElementsByClassName("SecondaryDelete")[0].style.display =
          "block";
        orderUploadFormConfigVar[3].columns[0].rows[9][0].hide = false;
        orderUploadFormConfigVar[4].columns[0].rows[8][0].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[8][1].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[8][2].validators[0].value = false;
        orderUploadFormConfigVar[4].columns[0].rows[8][2].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[0][0].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[1][0].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[1][1].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[1][2].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[1][2].validators[0].value = false;

        orderUploadFormConfigVar[4].columns[0].rows[2][0].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[2][1].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[2][2].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[2][2].validators[0].value = false;

        orderUploadFormConfigVar[4].columns[0].rows[3][0].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[3][1].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[4][0].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[4][1].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[5][0].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[5][1].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[6][0].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[6][1].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[7][0].hide = true;
        orderUploadFormConfigVar[4].columns[0].rows[7][1].hide = true;

        setBindDataOnchange(true);
        setIsTertiaryDeleted(true)
        setTertiaryInjuryRemove(undefined);
      }

      setIsConfirmedDelete(false);
    }
  }, [isConfirmedDelete])

  const deleteSecondaryInujry = () => {

    setOpenConfirmDialog(true);
    setSecondaryInujryRemove(1)
    setDeleteMessage(`Are you sure you want to delete Secondary Injury?`)
  };

  const deleteTertiaryInjury = () => {

    setOpenConfirmDialog(true);
    setTertiaryInjuryRemove(1)
    setDeleteMessage(`Are you sure you want to delete Tertiary Injury?`)


  };
  useEffect(() => {
    if (isTertiaryDeleted) {
      let tempDemographicModel = demographicModel;
      let tempCurrent = current;
      tempCurrent.tertiary_amountAwarded = undefined
      tempCurrent.tertiary_injuryOtherType = undefined
      tempCurrent.tertiary_injuryType = undefined
      tempCurrent.tertiary_length = undefined
      tempCurrent.tertiary_noOfInjections = undefined
      tempCurrent.tertiary_noOfSurgeries = undefined
      tempCurrent.tertiary_otherPartOfBody = undefined
      tempCurrent.tertiary_partOfBody = undefined
      tempCurrent.tertiary_treatmentType = undefined
      tempCurrent.tertiary_typeOfInjury = undefined
      tempCurrent.tertiary_otherTypeOfInjury = undefined
      if (tempDemographicModel?.injuries?.length > 0) {
        tempDemographicModel.injuries.splice(2, 1);
      }
      setCurrent(tempCurrent)
      setDemographicModel(tempDemographicModel)
      setIsTertiaryDeleted(false)
    }
  }, [isTertiaryDeleted])

  useEffect(() => {
    if (isSecondaryDeleted) {
      let tempDemographicModel_secondary = demographicModel;
      let tempCurrent_secondary = current;
      tempCurrent_secondary.secondary_injuryType = undefined
      tempCurrent_secondary.secondary_injuryOtherType = undefined
      tempCurrent_secondary.secondary_otherPartOfBody = undefined
      tempCurrent_secondary.secondary_partOfBody = undefined
      tempCurrent_secondary.secondary_noOfSurgeries = undefined
      tempCurrent_secondary.secondary_noOfInjections = undefined
      tempCurrent_secondary.secondary_treatmentType = undefined
      tempCurrent_secondary.secondary_length = undefined
      tempCurrent_secondary.secondary_amountAwarded = undefined
      tempCurrent_secondary.secondary_typeOfInjury = undefined
      tempCurrent_secondary.secondary_otherTypeOfInjury = undefined
      if (tempDemographicModel_secondary?.injuries?.length > 0) {
        tempDemographicModel_secondary.injuries.splice(1, 1);
      }
      setCurrent(tempCurrent_secondary)
      setDemographicModel(tempDemographicModel_secondary)
      setIsSecondaryDeleted(false)
    }
  }, [isSecondaryDeleted])

  const buttonNormalClick = (id, key) => {
    if (key === "add_inury") {
      document.getElementsByClassName(tertiaryinformationdiv)[0].style.display =
        "block";
      document.getElementsByClassName(tertiaryinformationdiv)[1].style.display =
        "block";
      document.getElementsByClassName("SecondaryDelete")[0].style.display =
        "none";
      orderUploadFormConfigVar[3].columns[0].rows[9][0].hide = true;
      orderUploadFormConfigVar[4].columns[0].rows[8][0].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[8][1].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[0][0].hide = true;

      orderUploadFormConfigVar[4].columns[0].rows[1][0].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[1][1].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[2][0].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[2][1].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[3][0].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[3][1].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[4][0].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[4][1].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[5][0].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[5][1].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[6][0].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[6][1].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[7][0].hide = false;
      orderUploadFormConfigVar[4].columns[0].rows[7][1].hide = false;
    } else if (key === "add_secondary_injury") {
      document.getElementsByClassName(
        secondaryinformationdiv
      )[0].style.display = "block";
      document.getElementsByClassName(
        secondaryinformationdiv
      )[1].style.display = "block";
      document
        .getElementsByClassName("control-add_secondary_injury")[0]
        .closest(".row").style.display = "none";
      orderUploadFormConfigVar[2].columns[0].rows[8][0].hide = true;
      orderUploadFormConfigVar[3].columns[0].rows[0][0].hide = true;

      orderUploadFormConfigVar[3].columns[0].rows[1][0].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[1][1].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[2][0].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[2][1].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[3][0].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[3][1].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[4][0].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[4][1].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[5][0].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[5][1].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[6][0].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[6][1].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[7][0].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[7][1].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[8][0].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[8][1].hide = false;
      orderUploadFormConfigVar[3].columns[0].rows[9][0].hide = false;
    }

    setBindDataOnchange(true);
  };

  const [resetCounty, setResetCounty] = useState(null);
  const [stateChanged, setStateChanged] = useState(false);
  const onChangeHandler = (event, key) => {

    if (key === "resolution") {
      setResolution(event.target.value);
      if (event.target.value === "Unsettled") {
        //// removes all elemnets from index 7 to last index of the array

        const tempRemove = orderUploadFormConfigVar;
        tempRemove[1].columns[0].rows.splice(7);
        setOrderUploadFormConfigVar(tempRemove);
      }
      if (event.target.value === "Settled") {
        //// Economic Field

        if (orderUploadFormConfigVar[1].columns[0].rows.length === 7) {
          const tempAdd = orderUploadFormConfigVar;
          tempAdd[1].columns[0].rows.push(...dataAfterUnSettled);
          setOrderUploadFormConfigVar(tempAdd);
        }
      }
    }

    const typeOfInjury = key.indexOf("_") !== -1 ? key.split("_")[0] : key;
    const othertypeOfInjury = key.indexOf("_") !== -1 ? key.split("_")[1] : key;
    if (key === "state") {

      let filterCountyByState = [];
      if (event.target.value !== '') {
        orderUploadFormConfigVar[1].columns[0].rows[3][1].options = [];
        filterCountyByState = countyData.filter((val) =>
          val.key == event.target.value
        );
      }
      filterCountyByState.unshift({
        key: selectCounty,
        label: selectCounty,
        value: "",
      });
      setResetCounty(filterCountyByState);
    }

    if (typeOfInjury === "primary") {

      if (othertypeOfInjury === "typeOfInjury") {
        if (event.target.value === "5") {
          orderUploadFormConfigVar[2].columns[0].rows[7][2].hide = false;
          orderUploadFormConfigVar[2].columns[0].rows[7][2].validators[0].value = true;
        } else {
          orderUploadFormConfigVar[2].columns[0].rows[7][2].hide = true;
          orderUploadFormConfigVar[2].columns[0].rows[7][2].validators[0].value = false;
        }
      }

      if (othertypeOfInjury === "injuryType") {
        if (event.target.value === "38") {
          orderUploadFormConfigVar[2].columns[0].rows[0][2].hide = false;
          orderUploadFormConfigVar[2].columns[0].rows[0][2].validators[0].value = true;
        } else {
          orderUploadFormConfigVar[2].columns[0].rows[0][2].hide = true;
          orderUploadFormConfigVar[2].columns[0].rows[0][2].validators[0].value = false;
        }
      }
      if (othertypeOfInjury === "partOfBody") {
        if (event.target.value === "39") {
          orderUploadFormConfigVar[2].columns[0].rows[1][2].hide = false;
          orderUploadFormConfigVar[2].columns[0].rows[1][2].validators[0].value = true;
        } else {
          orderUploadFormConfigVar[2].columns[0].rows[1][2].hide = true;
          orderUploadFormConfigVar[2].columns[0].rows[1][2].validators[0].value = false;
        }
      }
      setPrimaryInjury({
        ...primaryInjury,
        [key.indexOf("_") !== -1 ? key.split("_")[1] : key]: event.target.value,
      });
      setCurrent({ ...current, [key]: event.target.value })
    } else if (typeOfInjury === "secondary") {
      if (othertypeOfInjury === "typeOfInjury") {
        if (event.target.value === "5") {
          orderUploadFormConfigVar[3].columns[0].rows[8][2].hide = false;
          orderUploadFormConfigVar[3].columns[0].rows[8][2].validators[0].value = true;
        } else {
          orderUploadFormConfigVar[3].columns[0].rows[8][2].hide = true;
          orderUploadFormConfigVar[3].columns[0].rows[8][2].validators[0].value = false;
        }
      }
      if (othertypeOfInjury === "injuryType") {
        if (event.target.value === "38") {
          orderUploadFormConfigVar[3].columns[0].rows[1][2].hide = false;
          orderUploadFormConfigVar[3].columns[0].rows[1][2].validators[0].value = true;
        } else {
          orderUploadFormConfigVar[3].columns[0].rows[1][2].hide = true;
          orderUploadFormConfigVar[3].columns[0].rows[1][2].validators[0].value = false;
        }
      }
      if (othertypeOfInjury === "partOfBody") {
        if (event.target.value === "39") {
          orderUploadFormConfigVar[3].columns[0].rows[2][2].hide = false;
          orderUploadFormConfigVar[3].columns[0].rows[2][2].validators[0].value = true;
        } else {
          orderUploadFormConfigVar[3].columns[0].rows[2][2].hide = true;
          orderUploadFormConfigVar[3].columns[0].rows[2][2].validators[0].value = false;
        }
      }
      setSecondaryInjury({
        ...secondaryInjury,
        [key.indexOf("_") !== -1 ? key.split("_")[1] : key]: event.target.value,
        type: "Secondary",
      });
      setCurrent({ ...current, [key]: event.target.value })
    } else if (typeOfInjury === "tertiary") {
      if (othertypeOfInjury === "typeOfInjury") {
        if (event.target.value === "5") {
          orderUploadFormConfigVar[4].columns[0].rows[8][2].hide = false;
          orderUploadFormConfigVar[4].columns[0].rows[8][2].validators[0].value = true;
        } else {
          orderUploadFormConfigVar[4].columns[0].rows[8][2].hide = true;
          orderUploadFormConfigVar[4].columns[0].rows[8][2].validators[0].value = false;
        }
      }
      if (othertypeOfInjury === "injuryType") {
        if (event.target.value === "38") {
          orderUploadFormConfigVar[4].columns[0].rows[1][2].hide = false;
          orderUploadFormConfigVar[4].columns[0].rows[1][2].validators[0].value = true;
        } else {
          orderUploadFormConfigVar[4].columns[0].rows[1][2].hide = true;
          orderUploadFormConfigVar[4].columns[0].rows[1][2].validators[0].value = false;
        }
      }
      if (othertypeOfInjury === "partOfBody") {
        if (event.target.value === "39") {
          orderUploadFormConfigVar[4].columns[0].rows[2][2].hide = false;
          orderUploadFormConfigVar[4].columns[0].rows[2][2].validators[0].value = true;
        } else {
          orderUploadFormConfigVar[4].columns[0].rows[2][2].hide = true;
          orderUploadFormConfigVar[4].columns[0].rows[2][2].validators[0].value = false;
        }
      }
      setTertiaryInjury({
        ...tertiaryInjury,
        [key.indexOf("_") !== -1 ? key.split("_")[1] : key]: event.target.value,
        type: "Tertiary",
      });
      setCurrent({ ...current, [key]: event.target.value })
    } else {
      let val = event.target.value;
      if (key === "loss" || key === "awarded") {
        val = val.replace(/[^\d\.]/g, "").replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
      if (key === "limit") {
        val = val.replace(/[^\d]/g, "").replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }

      if (key === "state") {
        setDemographicModel({ ...demographicModel, [key]: val, county: "" });
        setCurrent({ ...current, [key]: val, county: "" })
        setStateChanged(true);
      } else {
        if (key === "splitRadio") {
          setDemographicModel({ ...demographicModel, ["split"]: val });
          setCurrent({ ...current, ["split"]: val })
        }
        else {
          setDemographicModel({ ...demographicModel, [key]: val });
          setCurrent({ ...current, [key]: val })
        }
      }
    }
    setBindDataOnchange(true);
  };
  useEffect(() => {
    if (stateChanged) {
      orderUploadFormConfigVar[1].columns[0].rows[3][1].options = resetCounty;
      setStateChanged(false);
    }
  }, [stateChanged]);
  useEffect(() => {
    if (bindDataOnchange) {
      const reAssignDemographicModelforPrimary = demographicModel;
      if (reAssignDemographicModelforPrimary?.injuries) {
        reAssignDemographicModelforPrimary.injuries[0] = primaryInjury;
      } else {
        reAssignDemographicModelforPrimary.injuries = [];
        reAssignDemographicModelforPrimary.injuries[0] = primaryInjury;
      }
      setDemographicModel(reAssignDemographicModelforPrimary);
      setBindDataOnchange(true);
    }
  }, [primaryInjury]);
  const bindDeleteButton = () => {
    const elements = document.getElementsByClassName("SecondaryDelete");
    if (elements !== null && elements !== undefined && elements.length > 0) {
      elements[0].addEventListener("click", deleteSecondaryInujry, false);
    }
    const elementsTeritary = document.getElementsByClassName("TeritaryDelete");
    if (
      elementsTeritary !== null &&
      elementsTeritary !== undefined &&
      elementsTeritary.length > 0
    ) {
      elementsTeritary[0].addEventListener(
        "click",
        deleteTertiaryInjury,
        false
      );
    }
  };
  useEffect(() => {
    if (bindDataOnchange) {
      bindDeleteButton();
      const reAssignDemographicModelforSecondary = demographicModel;
      if (reAssignDemographicModelforSecondary?.injuries) {
        reAssignDemographicModelforSecondary.injuries[1] = secondaryInjury;
      } else {
        reAssignDemographicModelforSecondary.injuries = [];
        reAssignDemographicModelforSecondary.injuries[1] = secondaryInjury;
      }
      setDemographicModel(reAssignDemographicModelforSecondary);
      setBindDataOnchange(true);
    }
  }, [secondaryInjury]);

  useEffect(() => {
    if (bindDataOnchange) {
      const elements = document.getElementsByClassName("SecondaryDelete");
      if (elements !== null && elements !== undefined && elements.length > 0) {
        elements[0].addEventListener("click", deleteSecondaryInujry, false);
      }
      const elementsTeritary =
        document.getElementsByClassName("TeritaryDelete");
      if (
        elementsTeritary !== null &&
        elementsTeritary !== undefined &&
        elementsTeritary.length > 0
      ) {
        elementsTeritary[0].addEventListener(
          "click",
          deleteTertiaryInjury,
          false
        );
      }
      const reAssignDemographicModelforTertiary = demographicModel;
      if (reAssignDemographicModelforTertiary?.injuries) {
        reAssignDemographicModelforTertiary.injuries[2] = tertiaryInjury;
      } else {
        reAssignDemographicModelforTertiary.injuries = [];
        reAssignDemographicModelforTertiary.injuries[2] = tertiaryInjury;
      }
      setDemographicModel(reAssignDemographicModelforTertiary);
      setBindDataOnchange(true);
    }
  }, [tertiaryInjury]);

  useEffect(() => {
    if (bindDataOnchange) {
      dataBinding();
      const elements = document.getElementsByClassName("SecondaryDelete");
      const elementsTeritary =
        document.getElementsByClassName("TeritaryDelete");

      if (
        elements !== null &&
        elements !== undefined &&
        elements.length === 1
      ) {
        elements[0].addEventListener("click", deleteSecondaryInujry, false);
      }
      if (
        elementsTeritary !== null &&
        elementsTeritary !== undefined &&
        elementsTeritary.length === 1
      ) {
        elementsTeritary[0].addEventListener(
          "click",
          deleteTertiaryInjury,
          false
        );
      }
    }
  }, [bindDataOnchange]);

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading your content...">
      <div
        id="demographicdetails"
        className="order-upload-data pageWrap demographicdata"
      >
        <div className="upload-form demog">
          <DynamicForm
            className={`order-upload-form`}
            title=" "
            buttonSubmit="Submit"
            defaultValues={current}
            model={orderUploadFormConfigVar}
            onSubmit={(model) => {
              onFormSubmit(model);
            }}
            disableSubmitOnInValid={disableSubmitButton}
            design="advanceMaterial"
            materialOutline={true}
            onChange={onChangeHandler}
            customValidators={customeValidation}
            hideEconomicLoss={resolution}
            showIcons={true}
            buttonNormalClick={buttonNormalClick}
            grammarly={{
              active: true,
              grammarlyClientId: GRAMERLY_CLIENT_ID
            }}
          />


        </div>
        {issubmitted && (
          <Modal show={issubmitted} onHide={() => close()}>
            <Modal.Header closeButton>
              <Modal.Title>Demographics</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Demographics has been submitted successfully
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => close()}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {fillAllfields && (
          <Modal show={fillAllfields} onHide={() => close()}>
            <Modal.Header closeButton>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <label style={{ color: "red" }}>
                Please fill out all mandatory fields.
              </label>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => close()}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {isdisplayAlertError === true && (
          <AlertDialog
            isdisplayAlert={false}
            isdisplayAlertError={isdisplayAlertError}
            updatedisplayAlertError={updatedisplayAlert}
          ></AlertDialog>
        )}

        {openConfirmDialog &&
          <AlertDialog isdisplayAlert={false}
            isdisplayAlertError={false}
            openConfirmDialog={openConfirmDialog}
            closeConfirmDialog={closeConfirmDialog}
            deleteMessage={deleteMessage}
          ></AlertDialog>}

      </div>
    </LoadingOverlay>
  );
}
