import React, { useEffect, useState } from "react";

export const Paginator = ({
    totalRecoards,
    recordsPerPage,
    onPageChange,
    onChangeRowsPerPage,
    rowsPerPageList = [],
    maxPageLimit,
    enablePerPageDropDown = false,
    initialPage,
    rowCount,
    ...props
}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [pages, setPages] = useState([]);
    const totalPages = Math.ceil(totalRecoards / recordsPerPage);
    useEffect(() => {
        setCurrentPage(initialPage - 1);
        calculatePageRange("right", totalPages, initialPage - 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialPage, totalRecoards, rowCount]);
    const calculatePageRange = (direction, totalPagesVar, currentPageVar) => {
        const totalPagesRange = Array.from(Array(totalPages).keys());
        let lastRangeIndex = currentPageVar + maxPageLimit;
        let startIndex;
        if (direction === "right") {
            if (totalPagesVar - maxPageLimit > currentPageVar) {
                startIndex = currentPageVar;
            } else {
                startIndex = totalPagesVar - maxPageLimit;
            }
        } else {
            startIndex =
                currentPageVar - maxPageLimit + 1 >= 0
                    ? currentPageVar - maxPageLimit + 1
                    : 0;
            lastRangeIndex = Math.max(startIndex + maxPageLimit, currentPageVar + 1);
        }

        const range = totalPagesRange.slice(
            startIndex >= 0 ? startIndex : 0,
            lastRangeIndex
        );
        setPages(range);
    };
    const handleClick = (e, page) => {
        const direction = currentPage > page ? "left" : "right";
        setCurrentPage(page);
        calculatePageRange(direction, totalPages, page);
        onPageChange(e, page);
    };

    return (
        <div className="row" style={{justifyContent:'right'}}>
            <div className="col-md-12">
                {enablePerPageDropDown ? (
                    <>
                        <label>Rows Per Page</label>
                        <select
                            onChange={(e) => {
                                onChangeRowsPerPage(e, e.target.value);
                            }}
                            value={recordsPerPage}
                        >
                            {rowsPerPageList.map((item, index) => {
                                return <option value={item}>{item}</option>;
                            })}
                        </select>{" "}
                    </>
                ) : (
                    ""
                )}
            </div>
            <div className="col-md-12 paginator">
            <button
                    disabled={0 === currentPage}
                    className={0 === currentPage ? "disabled" : ""}
                    onClick={(e) => {
                        handleClick(e, 0);
                    }}
                    title="Go to first page"
                >
                &#171;
                </button>
                <button
                    disabled={0 === currentPage}
                    className={0 === currentPage ? "disabled" : ""}
                    onClick={(e) => {
                        handleClick(e, currentPage - 1);
                    }}
                    title="Go to previous page"
                >
                    &#8249;
                </button>
                {pages.map((page, index) => {
                    return (
                        <button
                            key={index}
                            disabled={page === currentPage}
                            className={page === currentPage ? "disabled" : ""}
                            onClick={(e) => {
                                handleClick(e, page);
                            }}
                        >
                            {page + 1}
                        </button>
                    );
                })}
                <button
                    disabled={totalPages - 1 === currentPage}
                    className={totalPages - 1 === currentPage ? "disabled" : ""}
                    onClick={(e) => {
                        handleClick(e, currentPage + 1);
                    }}
                    title="Go to next page"
                >
                    &#8250;
                </button>
                <button
                    disabled={totalPages - 1 === currentPage}
                    className={totalPages - 1 === currentPage ? "disabled" : ""}
                    onClick={(e) => {
                        handleClick(e, totalPages - 1);
                    }}
                    title="Go to last page"
                >
                    &#187;
                </button>
            </div>
        </div>
    );
};
