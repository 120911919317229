import { Grid } from "@material-ui/core";
import React from "react";
import "./changePassword.scss";
import axios from "axios";
import { post } from "../../api/api";
import {
  CHANGE_PASSWORD,
  VERIFY_ACCOUNT_WITH_CODE,
  CHANGE_PASSWORD_WITH_CODE,
} from "../../api/baseURL";
import Paper from "@material-ui/core/Paper";
import DynamicForm from "f1-dynamic-form-react";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import namlogo from "../../assets/img/namlogo.png";
//// const passwordLength="Password's length must be between 6 and 16.";    

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    const verifyCode = this.props.match.params?.verifyCode;
    this.state = {
      isResetPassword:
        this.props.match.params?.verifyCode !== undefined &&
        this.props.match.params?.verifyCode !== null &&
        this.props.match.params?.verifyCode !== "",
      current: { id: 1 },
      showMsg: false,
      message: "",
      variant: "",
      verifyCode: verifyCode,
    };
  }
  verifyToken = () => {
    post(`${VERIFY_ACCOUNT_WITH_CODE}?verifyCode=${this.state.verifyCode}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data !== "Account Verified Successfully") {
            this.props.history.push("/");
          }
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          this.setState({
            message: "Invalid token.",
            variant: "error",
            showMsg: true,
          });
          this.props.history.push("/");
        }
      });
  };
  componentDidMount() {
    if (this.state.isResetPassword) {
      this.verifyToken();
    }
    console.log(this);
  }
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showMsg: false });
  };
  logout = () => {
    window.localStorage.clear();
    localStorage.clear();
    this.props.history.push("/");
  };
  submitResetPassword = (event) => {
    if (
      event.errors.newPassword === undefined &&
      event.errors.confirmPassword === undefined &&
      event.confirmPassword &&
      event.newPassword
    ) {
      if (event.confirmPassword === event.newPassword) {
        const url = `${CHANGE_PASSWORD_WITH_CODE}?verifyCode=${this.state.verifyCode}&newPassword=${event.newPassword}`;
        axios
          .post(url)
          .then((res) => {
            if (res.data.changePassword === true) {
              this.setState({
                message: "Password changed successfully",
                showMsg: true,
                variant: "success",
              });
              setTimeout(() => {
                this.logout();
              }, 800);
            } else {
              this.setState({
                message: res.data,
                variant: "error",
                showMsg: true,
              });
              setTimeout(() => {
                this.logout();
              }, 800);
            }
          })
          .catch((error) => {
            this.setState({
              message: "There is an error.Please try again later.",
              variant: "error",
              showMsg: true,
            });
            setTimeout(() => {
              this.logout();
            }, 800);
          });
      } else {
        this.setState({
          message: "New password and confirm password must match",
          variant: "error",
          showMsg: true,
        });
      }
    }
  };
  submitForm = (event, value) => {
    if (event.email && event.oldPassword && event.newPassword) {
      const url = `${CHANGE_PASSWORD}?email=${event.email}&oldPassword=${event.oldPassword}&newPassword=${event.newPassword}`;
      axios
        .post(url)
        .then((res) => {
          if (res.data === true) {
            this.setState({
              message: "Password changed successfully",
              showMsg: true,
              variant: "success",
            });
            setTimeout(() => {
              this.logout();
            }, 800);
          } else {
            this.setState({
              message: res.data,
              variant: "error",
              showMsg: true,
            });
          }
        })
        .catch((error) => {
          this.setState({
            message: "There is an error.Please try again later.",
            variant: "error",
            showMsg: true,
          });
          console.log(error);
        });
    }
  };
  render() {
    return (
      <div className="changePass main-container loginPage">
        <div className="homePage">
          {/* <Header history={this.props.history} /> */}
          <div className="analytics-section loginBox">
            <Grid item sm={12} md={12}>
              <Grid style={{ margin: "auto" }}>
                <Paper style={{ textAlign: "left" }}>
                  <div className="loginheadbox">
                    <div>
                      <img src={namlogo} />
                    </div>
                  </div>
                  <DynamicForm
                    key={this.state.current.id}
                    className="form horizontal"
                    title="Reset Password"
                    buttonSubmit="Submit"
                    model={ [
                      {
                        key: "newPassword",
                        type: "password",
                        disabled: false,
                        id: "newPassword",
                        showPassword: true,
                        //icon: "user",
                        label: "New Password",
                        validators: [
                          {
                            name: "required",
                            value: true,
                            validationMessage: "New Password is required.",
                          },
                          {
                            name: "minLength",
                            value: 6,
                            validationMessage: "Min length for New Password is 6.",
                          },
                          {
                            name: "maxLength",
                            value: 10,
                            validationMessage:
                            "Password's length must be between 6 and 10.",
                          },
                        ],
                      },
                      {
                        key: "confirmPassword",
                        type: "password",
                        disabled: false,
                        id: "confirmPassword",
                        showPassword: true,
                        //icon: "user",
                        label: "Confirm Password",
                        validators: [
                          {
                            name: "required",
                            value: true,
                            validationMessage: "Confirm Password is required.",
                          },
                          {
                            name: "minLength",
                            value: 6,
                            validationMessage: "Min length for Confirm Password is 6.",
                          },
                          {
                            name: "maxLength",
                            value: 10,
                            validationMessage:
                            "Password's length must be between 6 and 10.",
                          },
                        ],
                      },
                    ]}
                    showIcons={true}
                    onSubmit={
                      this.state.isResetPassword
                        ? this.submitResetPassword
                        : this.submitForm
                    }
                    disableSubmitOnInValid={false}
                    customValidators={this.customValidators}
                    displayInformation={true}
                    design="bootrstap" // material/bootrstap/advanceMaterial/advanceBootstrap
                    materialOutline={true}
                    extErrors={this.state.extErrors}
                    onChange={this.onChangeHandler}
                  />
                  <Snackbar
                    open={this.state.showMsg}
                    autoHideDuration={1000}
                    onClose={() =>
                      this.handleClose({
                        vertical: "bottom",
                        horizontal: "right",
                      })
                    }
                  >
                    <Alert severity={this.state.variant}>
                      {this.state.message}
                    </Alert>
                  </Snackbar>
                </Paper>
              </Grid>
            </Grid>{" "}
          </div>
        </div>
      </div>
    );
  }
}
export default ChangePassword;
