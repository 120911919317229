import React, { useState, useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import CircleIcon from "@mui/icons-material/Circle";
import "react-select-search/style.css";

function RBCToolbar(props) {
  const { label, date, view, views, onView, onNavigate } = props.props;
  const [month, setMonth] = useState("January");
  const mMonth = moment(date).format("MMMM");
  useEffect(() => {
    setMonth(mMonth);
  }, [mMonth]);
  const goToView = (toView) => {
    onView(toView);
  };
  const goToBack = () => {
    onNavigate("PREV");
  };
  const goToNext = () => {
    onNavigate("NEXT");
  };

  return (
    <div className="row">
      <div className="rbc-toolbar col-md-6" style={{ justifyContent: "flex-start" }}>
        <div className="rbc-btn-group date1">
          <button className="prebtn" onClick={goToBack}>
            {" "}
            <span className="prev-icon">&#8249;&#8249;</span>
          </button>
          {view === "month" ? (
            <>
              <span className="rbc-month">{month}</span>,
              <span className="rbc-year"> {moment(date).format("YYYY")}</span>
            </>
          ) : (
            label
          )}
          <button className="nxtbtn" onClick={goToNext}>
            <span className="prev-icon">&#8250;&#8250;</span>
          </button>
          
        </div>
        <div className="rbc-btn-group date2">
          {views?.map((item) => (
            <button
              onClick={() => goToView(item)}
              key={item}
              className={clsx({ "rbc-active": view === item })}>
              {item}
            </button>
          ))}
        </div>
      </div>
      <div className="col-md-6 mt-3" style={{ justifyContent: "flex-end" }}>
        {/* <div className="topBar">
          {hearingOfficers.length > 0 && (
            <div>
              <p style={{ marginRight: "15px" }}>Hearing Officer</p>
              {hearingOfficersrender()}
            </div>
          )}
        </div> */}
        <div className="eventTypes">
          <div className="eventList">
            <CircleIcon
              style={{
                color: "#F27C47",
                fontSize: "0.6rem",
                marginRight: "5px",
                position: "relative",

              }}
              size="small"
            />
            <p>Arbitration Event</p>
          </div>
          <div className="eventList">
            <CircleIcon
              style={{
                color: "black",
                fontSize: "0.6rem",
                marginRight: "5px",
                position: "relative",

              }}
              size="small"
            />
            <p>Mediation Event</p>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default RBCToolbar;