import moment from "moment";
import React from "react";
import { BASE_URL } from '../../api/baseURL';
import * as Utility from "../../utils/utility";

const CommercialTimesheetTemplate = (props) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const getMonth = (timesheet) => {
        const time = "01 " + timesheet;
        const date = new Date(time);
        return monthNames[date.getMonth()] + ' ' + date.getFullYear();
    }

    const toUpperCase = (item) => {
        return item !== undefined && item !== null && item !== ""
            ? item.toUpperCase()
            : "";
    };
    const getTotalTime = () => {
        const timesheetArrDet = props.timeSheetDetails.commercialtimesheetDetails;
        const sum = timesheetArrDet !== null && timesheetArrDet !== undefined && timesheetArrDet.length > 0 ?
        timesheetArrDet.map(item => item.hearingDuration !== null && item.hearingDuration !== undefined && item.hearingDuration !== '' ? item.hearingDuration : 0).reduce((prev, next) => prev + next) : 0;
        return Utility.toHoursAndMinutesAbr(sum);
    }    
    const timeSheetDetails = props.timeSheetDetails;
    const timesheetArr = props.timeSheetDetails.commercialtimesheetDetails;


    return (
        <>

            <div id="arbitrationdecicionTemplate" style={{ display: "block", }}>
                <div>
                    <img
                        alt=""
                        src={`${BASE_URL}/static/media/namlogo.b809c154.png`}
                        style={{
                            display: "block",
                            width: "300px",
                            height: "100px",
                            marginLeft: "-10px"
                        }}
                    />
                </div>

                <div style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>
                    <span>
                        <b>STATE OF {toUpperCase(props.caseDetails["state"])}</b>
                    </span>
                </div>
            </div>
            <div>
                <div>
                    <div>
                        <div
                            className="text-left font-weight-bold"
                            style={{ padding: "5px 0px 7px" }}>
                        </div>
                    </div>
                    <div id="htmldoc" style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>
                        <hr style={{ border: '1px double #c7c7c7' }}></hr>
                        <table style={{ width: '100%' }}>
                            <tr>
                                <td style={{ width: '50%' }}>
                                    <div className="text-left font-weight-bold">
                                        <b> Case Caption: {props.caseDetails["caseName"]}</b>
                                    </div>
                                    <div className="text-left font-weight-bold">
                                        <b>
                                            NAM ID: {props.caseDetails["caseReference"]}
                                        </b>
                                    </div>
                                    <div className="text-left font-weight-bold">
                                        <b>
                                            Hearing Officer: {props.caseDetails["hearingOfficerName"]}
                                        </b>
                                    </div>
                                </td>

                            </tr>
                        </table>
                        <hr></hr>
                    </div>
                    <>
                        <div>
                            <div className="border pmasec"
                                style={{ marginTop: "20px", fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>
                                <div>
                                    <span >
                                        <div style={{ textAlign: 'left', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important' }}>

                                            {getMonth(props.timeSheetDetails.timesheetName)}
                                            <br />Time spent for this month {getTotalTime()}
                                            <br />
                                        </div>
                                    </span>
                                    <table style={{ width: '100%' }} border="1" cellPadding={3} cellSpacing={0}>
                                        <thead>
                                            <tr>
                                                <th style={{ background: '#ededed !important', fontWeight: 'bold', }} scope="col">Line Item</th>
                                                <th style={{ background: '#ededed !important', fontWeight: 'bold', }} scope="col">Date</th>
                                                <th style={{ background: '#ededed !important', fontWeight: 'bold', }} scope="col">Start Time</th>
                                                <th style={{ background: '#ededed !important', fontWeight: 'bold', }} scope="col">End Time</th>
                                                <th style={{ background: '#ededed !important', fontWeight: 'bold', }} scope="col">Net Time</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {timesheetArr !== null && timesheetArr !== undefined && timesheetArr.length > 0 && timeSheetDetails.commercialtimesheetDetails.map((timeSheet, index) => {
                                                return (
                                                    <tr >
                                                        <td style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>
                                                            {timeSheet.lineItem 
                                                                ? timeSheet.lineItem.replace("^", ", ")
                                                                : ""}
                                                        </td>
                                                        <td style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>{timeSheet.entryDate !== null && timeSheet.entryDate !== '' ? moment(timeSheet.entryDate).format('DD-MMM-YYYY') : ''}</td>

                                                        <td style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>{timeSheet.startTime !== null && timeSheet.startTime !== '' ? moment(timeSheet.startTime).format('hh:mm A') : ''}</td>
                                                        <td style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>{timeSheet.endTime !== null && timeSheet.endTime !== '' ? moment(timeSheet.endTime).format('hh:mm A') : ''}</td>
                                                        <td style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>{Utility.toHoursAndMinutesMonthlyAbr(timeSheet.hearingDuration)}</td>
                                                    </tr>)
                                            })
                                            }
                                            <tr>
                                                <th colspan="4" style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }} scope="row">Total Time</th>
                                                <td style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', }}>{getTotalTime()}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <span >
                                       <br></br>
                                       <br></br>
                                        <div className=""
                                            style={{ textAlign: 'left', width: "98%" }}
                                        >
                                             <span style={{ textAlign: 'left', fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', marginTop: '1rem' }}>
                                            <b>Notes:&nbsp;&nbsp;</b>
                                            </span>
                                            <span

                                                type="text"
                                                style={{ textAlign: 'left', width: "98%" }}

                                            >{props.timeSheetDetails.additionalNotes}</span>
                                        </div>
                                        {/* <div className="">
                                            <input
                                                className="input2"
                                                type="text"
                                                style={{ width: "98%" }}
                                            />
                                        </div>
                                        <div className="">
                                            <input
                                                className="input2"
                                                type="text"
                                                style={{ width: "98%" }}
                                            />
                                        </div>
                                        <div className="">
                                            <input
                                                className="input2"
                                                type="text"
                                                style={{ width: "98%" }}
                                            />
                                        </div>
                                        <div className="">
                                            <input
                                                className="input2"
                                                type="text"
                                                style={{ width: "98%" }}
                                            />
                                        </div> */}
                                        {/* <div className="">
                                            <input
                                                className="input2"
                                                type="text"
                                                style={{ width: "49%" }}
                                            /> &nbsp; &nbsp;&nbsp;
                                            <input
                                                className="input2"
                                                type="text"
                                                style={{ width: "48%" }}
                                            />
                                        </div> */}
                                    </span>
                                    <span className="">
                                        <div>
                                            {/* <div className="rightt text-left" style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', marginTop: '1rem', marginLeft: '25rem' }}>
                                                (Please sign)
                                            </div>
                                            <div className="rightt text-right" style={{ fontFamily: 'Calibri, sans-serif', fontSize: '35px!important', marginTop: '1rem', marginRight: '25rem' }}>
                                                (Print name)
                                            </div> */}
                                        </div>

                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </>
    );

};

export default CommercialTimesheetTemplate;