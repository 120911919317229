import CryptoJS from "crypto-js";

import {secKey} from "../api/baseURL";

export const encryptItem=(Key, value)=>{

    localStorage.setItem(Key,  CryptoJS.AES.encrypt(JSON.stringify(value),secKey).toString());

}

export const decryptItem=(Key)=>{

     let jbyte = CryptoJS.AES.decrypt(localStorage.getItem(Key),secKey);         
     return JSON.parse(jbyte.toString(CryptoJS.enc.Utf8))
}


