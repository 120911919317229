import axios from "axios";
import packageJson from "../package.json";
import { BASE_URL_FULCRUMONE_SSO_SERVICE } from "../src/api/baseURL"

export const cacheClear = () => {
    axios
        .get(`${BASE_URL_FULCRUMONE_SSO_SERVICE}/api/Isnewversion/${packageJson.version}`)
        .then((res) => {
            if (!res?.data) {    
                
             if(! (sessionStorage.getItem('version'))){ 
                if ('caches' in window) {
                    caches.keys().then((names) => {
                        // Delete all the cache files
                        names.forEach(name => {
                            caches.delete(name);
                        })
                    }); 
                          
                    // Makes sure the page reloads. Changes are only visible after you refresh.
                        localStorage.clear();
                        sessionStorage.clear();
                        sessionStorage.setItem('version','true');
                        window.location.reload(true);
                }  
             }
            }
        });    
};
