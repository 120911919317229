/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/style-prop-object */
import React from "react";
const SchedulingTemplateAfter48hrs = (props) => {
    return (
        <>
            <div id="SchedulingTemplateAfter48hrs" style={{ display: "block" }}>
                <div id="htmldoc">
                    <div
                        id="page1-div"
                        style={{ position: "relative", width: "892px", height: "1262px" }}
                    >
                        <h1>Schedule will be  prepared 48 hours before hearing date</h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SchedulingTemplateAfter48hrs;
