import * as types from '../constants/userConstant';
const initialState = {
    currentUser: {}
}

function userreducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_USER:
            return {
                ...state,
                currentUser: action.payload
            }
        default: return state
    }
}

export default userreducer
