import React from "react";
import './login.scss';
import '../../App.css';
import Header from '../header/loginHeader';
import Grid from '@material-ui/core/Grid';

class Logout extends React.Component {

  render() {
    return (
      <div className="main-container">
        <Header history={this.props.history} />

        <Grid container justify="center" alignItems="left" className="singleMidBox" >
          <h3>Thank you!</h3>
        </Grid>

      </div>
    );
  }
}

export default Logout;
