import React from "react";
import fieldSetOrderInfoJson from "./fieldSetOrderInfo.json";
import fieldSetDeliveryInfoLabelJson from "./fieldSetDeliveryInfoLabel.json";
import fieldSetShippingInfoJson from "./fieldSetShippingInfo.json";
import tertiaryInjury from "./tertiaryInjury.json";

const fieldSetOrderInfoLabel = () => {
  return (
    <div className="row">
      <h5 className="fieldset-title">
        <span className="circle"></span>
        Party / Case Information
      </h5>
    </div>
  );
};

const fieldSetShippingInfoLabel = () => {
  return (
    <div className="row">
      <h5 className="fieldset-title">
        <span className="circle"></span>
        Primary Injury
      </h5>
    </div>
  );
};

const fieldSetDeliveryInfoLabel = () => {
  return (
    <div className="row secondary-information-div demoborder">
      <h5 className="fieldset-title">
        <span className="circle"></span>
        Secondary Injury
        <button
          type="button"
          key="SecondaryDelete"
          style={{ float: "right", marginRight: "1.5rem" }}
          className="SecondaryDelete custom-label fa fa-trash"
          colmd="2"
          value=""
          iconclassname="fa fa-trash"
          icon="trash"
        ></button>
      </h5>
    </div>
  );
};

const fieldSetDeliveryInfoLabeltertariry = () => {
  return (
    <div className="row tertiary-information-div" style={{ display: "none" }}>
      <h5 className="fieldset-title">
        <span className="circle"></span>
        Tertiary Injury
        <button
          type="button"
          key="TeritaryDelete"
          style={{ float: "right", marginRight: "1.5rem" }}
          className="TeritaryDelete custom-label fa fa-trash"
          colmd="2"
          value=""
          iconclassname="fa fa-trash"
          icon="trash"
        ></button>
      </h5>
    </div>
  );
};

const emptyDiv = () => {
  return <div></div>;
};
export const demoGraphicFormConfig = [
  {
    fieldSetClassName: "",
    fieldSetLabelType: "component",
    fieldSetLabel: emptyDiv, //headingLabel,
    columns: [],
  },
  {
    fieldSetClassName: "order-information",
    fieldSetLabelType: "component", // string/component
    fieldSetLabel: fieldSetOrderInfoLabel,
    columns: fieldSetOrderInfoJson,
  },
  {
    fieldSetClassName: "primary-information",
    fieldSetLabelType: "component",
    fieldSetLabel: fieldSetShippingInfoLabel,
    columns: fieldSetShippingInfoJson,
  },
  {
    fieldSetClassName: "secondary-information-div",
    fieldSetLabelType: "component",
    fieldSetLabel: fieldSetDeliveryInfoLabel,
    columns: fieldSetDeliveryInfoLabelJson,
  },

  {
    fieldSetClassName: "tertiary-information-div",
    fieldSetLabelType: "component",
    fieldSetLabel: fieldSetDeliveryInfoLabeltertariry,
    columns: tertiaryInjury,
  },
];
