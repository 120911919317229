import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Accordion from 'react-bootstrap/Accordion';
import axios from "axios";
import { GET_TRAINING_VIDEOS } from "../../api/baseURL";

import './help.scss'
import Loading from "../../assets/img/loader.gif";
import F1Player from 'f1-video-player-react'
    
  
import "react-select-search/style.css";

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


// Create new plugin instance


const TrainingVideos = (props) => { 

  const [caseSubmission, setcaseSubmission] = useState([]);
  const [fileUrl, setfileUrl] = useState("");
  const [isLoaded, setisLoaded] = useState(false);
  const [isalert, setisalert] = useState(false);
  

  useEffect(() => {
    
      getInvoiceStatments();
    
  }, []);
  const getInvoiceStatments = () => {
    setisLoaded(false);
    const url = `${GET_TRAINING_VIDEOS}`;

    axios
      .get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.status === 200 && res.data !== null && res.data !== undefined) {

          setcaseSubmission(res.data);
          setfileUrl(res.data !== null && res.data !== undefined && res.data.length > 0
            ?
            
            res.data[0].fileLink : '');

        }
        setisLoaded(true);

      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
        <div className="casesContainer" style={{ marginTop: "6rem" }}>
        <div className="tableresponsivemd">
          <div><span><h5>Neutrals Portal NAM Videos</h5></span></div>
          
      
        <div className="submissions">
          {isLoaded ? (<>
            <div className="row" style={{ height: "100%" }}>
              <div className={caseSubmission !== null && caseSubmission.length === 0 ? "col-12" : "col-4"}>
                <div className="row">
                  <div className="container-fluid">
                    {caseSubmission !== null && caseSubmission.length === 0 && <div className="mb-1  row" style={{ justifyContent: 'center', }}>No statements available</div>}
                    {caseSubmission !== null && caseSubmission.length > 0 && <>
                      <div>
                        <div className="action mb-3" >

                          <Accordion defaultActiveKey="0" >
                            {caseSubmission !== null &&
                              <>
                              <Accordion.Item eventKey={`0`}>
                              {/* <Accordion.Header style={{ marginRight: '0px', }}> <div
                                      className="font-weight-bold float-start text-start col-12 mb-3"
                                      style={{ padding: "0" }}
                                    >    <i className="fa fa-folder-o" aria-hidden="true"></i>
                                      <span style={{ marginLeft: '.2rem', }}> </span> </div>
                                    </Accordion.Header> */}
                                    <Accordion.Body style={{ height: "28rem" ,overflow: "scroll"}}>
                                    {caseSubmission.map((file, index) => {

return (<>
       <div className={`file-submission file-link-div ${index === 0 ? "linkbody" : ""}`} id={`fileSubmission_${index}`}>
  <a className={`file-link`} href={() => { return false; }} rel="noopener noreferrer" target="_blank" onClick={(event) => {
    event.currentTarget.scrollIntoView();
    Array.from(document.querySelectorAll('.file-link-div')).forEach(function (button) {

      button.classList.remove("linkbody");
    });
    const parent = event.target.parentElement.parentElement;
    parent.classList.add("linkbody");
      setfileUrl(file.fileLink);
    
  }}>
    {/* <i className="fa fa-file file-submission-icon" aria-hidden="true"></i> */}
    <span className="link" style={{ marginLeft: '.2rem', }}>{file.fileName}
    </span>
  </a>
</div></>)
})}
</Accordion.Body>
                              </Accordion.Item>
                              </>}
                          </Accordion>
                        </div>
                      </div>
                    </>
                    }
                  </div>
                </div>
              </div>

              <div className="col-8" style={{ height: "100%" }}>
                {fileUrl !== '' &&
                    <div style={{ height: "720px" }}>
                    <F1Player 
            fluid={true}
            width={700}
            src={fileUrl}
          />
                    </div>
                  }
                {/* <object data={fileUrl} type="application/pdf">
              <ReportViewer
                reportUrl={fileUrl}
                width="100%"
                height="90%"
                style={{
                  border: "none",
                  top: "0px",
                  width: "100%",
                  height: "80vh",
                }}
                runat="server"
              />
              </object> */}
              </div>


              {isalert && (
                <Modal
                  show={isalert}
                  onHide={() => setisalert(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>{"Your document is being downloaded."}</Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => setisalert(false)}
                    >
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}
            </div>
          </>) : (
            <div className="LoadingData">
              <iframe src={Loading} width="100%" height="100%" allowtransparency="true" frameBorder="0" marginWidth="0" marginHeight="0" scrolling="no"></iframe>

              <h4>Loading...</h4>
            </div>)
          }
        </div>
      </div>
      </div>
          </>
  );
};


export default TrainingVideos;
