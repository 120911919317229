import React from "react";
import ContactUs from './ContactUs'
import TrainingVideos from './TrainingVideos'
const HelpDesk = () => {
  return (
    <>
         <div className="invoices casesContainer"
        style={{ marginTop: "5rem", padding: "0% 3%" }}>
 <div className="helpDesk">
    <ContactUs  />
    
    
    
    <TrainingVideos />
    </div>
    </div>
    </>
  )
}

export default HelpDesk;