import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ReviewTimesheetDialog(props) {
    const [open, setOpen] = React.useState(props.isdisplayAlert);

    useEffect(() => {
        console.log('in alert');
        //  setOpen(props.isdisplayAlert)
    }, [open, props.isdisplayAlert])
    const handleClose = () => {
        setOpen(false);
        props.updatedisplayAlert(false, 'close',props.index);
    };
    const handleSend = () => {
        setOpen(false);
        props.updatedisplayAlert(false, 'send',props.index);
    };
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Once sent for the review the timesheet entries cannot be edited. Are you sure you want to send it for the Review?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSend}>Yes</Button>
                    <Button onClick={handleClose} autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}