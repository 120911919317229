import React from "react";
import {EMAILCUSTOMERSERVICE,PHONECUSTOMERSERVICE} from "../../api/baseURL";
const ContactUs = () => {
  return (
    <>
      <div className="casesContainer" style={{ marginTop: "6rem" }}>
        <div className="tableresponsivemd">
          <div><span><h5>Neutrals Portal NAM Contact Information</h5></span></div>
          <div className="contactDiv">
            <table>
              <caption></caption>
              <tbody style={{ textAlign: "center" }}>
                <tr>
                  <td>Scheduling</td>
                  <td>
                    <a href="mailto:scheduling@namadr.com">
                      scheduling@namadr.com
                    </a>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Timesheets</td>
                  <td>
                    <a href="mailto:timesheets@namadr.com">
                      timesheets@namadr.com
                    </a>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Accounting</td>
                  <td>
                    <a href="mailto:accounting@namadr.com">
                      accounting@namadr.com
                    </a>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Decisions</td>
                  <td>
                    <a href="mailto:decisions@namadr.com">decisions@namadr.com</a>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Tech Support</td>
                  <td>
                    <a href="mailto:helpdesk@namadr.com">helpdesk@namadr.com</a>
                  </td>
                  <td><a href="tel:1-516-941-3211">1-516-941-3211</a></td>
                </tr>
                <tr>
                  <td>Customer Service</td>
                  <td>                  <a href={`mailto:${EMAILCUSTOMERSERVICE}`}>{EMAILCUSTOMERSERVICE}</a>
                  </td>
                  <td><a href={`tel:${PHONECUSTOMERSERVICE}`}>{PHONECUSTOMERSERVICE}</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactUs;
